
.availability-cta,
.newsletter-trigger{
	background-color: #0066B3;
	bottom: 60px;
	position: fixed;
	color: #fff;
	text-decoration: none;
	right: 20px;
	z-index: 899;
	padding: 10px 15px;
	line-height: 1;
	font-weight: 800;
	font-size: 16px;
	letter-spacing: 1px;
	text-align: right;
	outline: none!important;
	display: table;
	&.inline-btn{
		position:relative;
		bottom: auto;
		right:auto;
		z-index: auto;
		display: table;
   		margin: 15px auto;
	}
	@media only screen and (max-width: 767px){
		bottom: 0px;
		left: 0;
		right: 0;
		text-align: center;
	}
	&:focus,
	&:hover{
		 text-decoration: none;
		 color: #fff;
		 &:before {
			right: 0;
		}
	}
	&:before {
	    content: "";
	    position: absolute;
	    left: 0;
	    right: 100%;
	    height: 2px;
	    background-color: #DCB007;
	    bottom: 0;
	    transition: all .3s;
	}
}
#newsletterModal{
	button.close {
		padding: 0;
		float: none;
		margin: 0 auto;
		display: table;
		font-size: 34px;
	}
	&.in{
		.modal-dialog{
			transform: translate(0,-50%);
	 	}
	}
	.modal-dialog {
		top:50%;
		transform: translate(0,0);
		max-width: 420px;
		width: 100%;
		@media screen and (max-width: 767px) {
				display: table;
				margin: 0 auto;
		}
		.modal-content {
			border-radius: 0;
			.modal-body {
				padding: 15px 35px;
				@media screen and (max-width: 991px) {
						padding: 15px 25px;
				}
				@media screen and (max-width: 767px) {
						padding: 10px 15px;
					    display: table;
    					margin: 0 auto;
				}
			}
		}
	}
	.newsletter-form-block{
		padding: 30px 0;
		@media screen and (max-width: 991px) {
				padding: 25px 0;
		}
		@media screen and (max-width: 767px) {
				padding: 20px 0;
		}
	}
	.input-element{
		position: relative;
		padding-bottom: 20px;
		.error,
		.success{
			position: absolute;
			left: 0;
			top: 48px;
			font-size: 14px;
			font-weight: 500;
			@media screen and (max-width: 991px) {
				top:  43px;
			}
			@media screen and (max-width: 767px) {
					top:  38px;
			}
		}
	}
	.form-group{
		label {
			font-weight: 500;
		}
		.form-control{
				border-radius: 0;
				height: 45px;
				@media screen and (max-width: 991px) {
						height: 40px;
				}
				@media screen and (max-width: 767px) {
						height: 35px;
				}
		}
		.option [type=checkbox]+label {
		    padding: 0 0 0 55px;
		    line-height: 1.2;
				@media only screen and (max-width: 991px){
					padding: 0 0 0 52px;
				}
				@media only screen and (max-width: 767px){
					padding: 0 0 0 42px;
				}
		}
		.option [aria-invalid=false][type=checkbox]:not(:checked)+label,
		.option .invalid[type=checkbox]:not(:checked)+label{
				color:#ff0000;
				&:before {
						border: 1px solid #ff0000;
				}
		}
	}
	.btn-primary{
		font-size: 18px;
		font-family: "Roboto Condensed",sans-serif;
		font-weight: 400;
		padding: 6px 20px;
		border-radius: 0;
		background: $brand_color;
		border:2px solid $brand_color;
		margin: 20px auto 0;
		display: table;
		&:focus,
		&:hover{
				background: #FFF;
				color:$brand_color;
		}
	}
}
