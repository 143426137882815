
#hero_section.campaign_hero_section{
	height: calc(100vh - 140px);
	.hero-content {

	}

	h1.main-title {
	    font-size: 34px!important;	
	    @media only screen and (max-width: 1500px){
			font-size: 28px!important;
		}
		@media only screen and (max-width: 1200px){
			font-size: 24px!important;
		}
		@media only screen and (max-width: 991px){
			font-size: 18px!important;
		}
		
	}
	.description {		    
	    font-size: 30px;	
	    @media only screen and (max-width: 1200px){
			font-size: 26px;
		}
		@media only screen and (max-width: 991px){
			font-size: 22px;
		}
		@media only screen and (max-width: 767px){
			font-size: 18px;
			padding-left: $small_title_push;
		}	    
	}

	.stamp{
		position: absolute;
		width: 175px;
		z-index: 10;
		right: 1%;
		pointer-events: none;
		top: 0; 
		@media only screen and (max-width: 991px){
			width: 150px;
		}
		@media only screen and (max-width: 767px){
			width: 100px;
		}
	}
}

.campaign-details{
	padding:100px 0 0;
	@media only screen and (max-width: 991px){
		padding:70px 0 0;
	}
	@media only screen and (max-width: 767px){
		padding:50px 0 0;
	}
	.full-box-album-left{
		position: relative;
		padding:50px 0 0;
		margin-bottom: 50px; 

		@media only screen and (max-width: 991px){
			padding: 25px 0 0;
		}
		@media only screen and (max-width: 767px){
			padding: 10 0 0;
		}
		.scroll-to-form{
			margin-top:20px; 
		}
		.full-box-album-left-content{
		   	@media only screen and (max-width: 991px){
				min-height: 0!important;
			}
			h2{
				font-size: 60px;
				text-align: right;
				margin: 0 0 0 -30px;
				line-height: 1;			
				padding: 10px 0px 10px 10px;
				color: #656565;
				letter-spacing: -0.5px;
				text-shadow: -1px 1px 2px #fff;
				@media only screen and (max-width: 991px){
					font-size: 50px;
					margin: 0;
					text-align: left;
				}
				@media only screen and (max-width: 767px){
					font-size: 34px;
					
				}
			}
			.content{
				padding: 28px 0 20px 90px;
				@media only screen and (max-width: 1200px){
					padding: 25px 0 20px 60px;
				}
				@media only screen and (max-width: 991px){
					padding: 10px;
				}
	            h3{
	              font-size: 40px;
	              text-align: left;
	              padding: 10px 0px 10px 0px;
	              margin: 0;
	              color: #656565;
	              letter-spacing: -0.5px;
					@media only screen and (max-width: 991px){
						font-size: 32px;
					}
					@media only screen and (max-width: 767px){
						font-size: 28px;
					}
	            }
				a.btn{
					display: table;
					margin-top: 96px;
					padding: 5px 30px;	
					@media only screen and (max-width: 767px){
						font-size: 16px;
						padding: 5px 20px;
						margin: 50px 0 0;
					}
				}
			}
			&.extra_content{
				padding: 0;
			}
		}
		.album{
			
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			@media only screen and (max-width: 991px){
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				max-width: 750px;
				margin: 0 auto;
				.eqHeight{
					min-height: 0!important;
				}
			}
			.pic{
				padding: 10px;
				@media only screen and (max-width: 767px){
					padding: 5px;
				}			
				&.img-1{
					padding-left: 50px;
				}
				&.img-4{
					padding-right: 35px;
				}
				&.pushed{
					margin-top: 200px;
					@media only screen and (max-width: 991px){
						margin-top: 120px;
					}
					@media only screen and (max-width: 767px){
						margin-top: 50px;
					}
					@media only screen and (max-width: 480px){				
						 padding-top: 20px;
					}
				}
			}
		}
	}
	.content-block{
		
		padding: 0 0 50px 0;
		h3{
			font-size: 26px;			
			letter-spacing: 0;
		}
		
		.event-content{
			width: calc(100% - 500px);
			float: left;
			padding-right: 160px;
			padding-left: 60px;
			color:#656565;
			@media only screen and (max-width: 1200px){
				padding: 60px;
			}
			@media only screen and (max-width: 991px){
				width: 50%;
				padding: 30px;
			}
			@media only screen and (max-width: 767px){
				padding: 15px;
				width: 100%;
			}
			
			.event-description{
				font-size: 16px;
				padding: 20px 0;
				font-weight: 300;
			}
			.event-info{
				font-size: 16px;
				padding: 20px 0;
				font-weight: 300;
				p{
					margin: 0;
				}
			}
			.join-event{
				margin-top: 20px;
			}
		}
		
		.sidebar{
			float: left;
			width: 500px;
			padding: 20px;
			position: relative;
			
			@media only screen and (max-width: 991px){
				width: 50%;
			}
			@media only screen and (max-width: 767px){
				width: 100%;
			}
			
			&:before{
				position: absolute;
				content: "";
				top: 0;
				bottom: 0;
				left: 0;
				right: -2000px;
				background: #f5f5f5;
				z-index: -1;
			}
			
			.event-joinus{
				text-align: left;
				position: relative;
				
				.left{
					width: 60px; 
					float: left;
				}
				.right{
					width: calc(100% - 60px);
					float: left;
				}
			}
			.event-keywords{
				text-align: left;
				position: relative;			
			}
			.actions{
				a{
					margin: 10px 0;
					min-width: 192px;
					text-align: center;
					
				}
			}
		}	
	}
	.campaign_additional{
		
		.section-title{
			position:relative;
			display: table;
			margin:0 auto;
			padding:0 55px;
			padding:0  $title_push;
			@media only screen and (max-width: 767px){
				padding:0 $small_title_push;			
			}
			&:after{
				border-top: 1px solid;
				content: '';
				left: auto;
				right: 0;
				margin-top: .5em;
				position: absolute;
				width: calc(55px - 20px);
				@media only screen and (max-width: 767px){
					width: calc(#{$small_title_push} - 20px);	
				}
			}
		}
		.campaign_additional_content{
			border:2px solid #000;
			padding:10px;
		}
	}	
	.fleet-info-title{
		background: #001a33;
		color: #fff;	
		margin-top: 50px;		
		padding: 30px 0;
		.section-title{
			position:relative;
			display: table;
			margin:0 auto;
			padding:0 55px;
			padding:0  $title_push;
			@media only screen and (max-width: 767px){
				padding:0 $small_title_push;			
			}
			&:after{
				border-top: 1px solid;
				content: '';
				left: auto;
				right: 0;
				margin-top: .5em;
    			top: 0;
				position: absolute;
				width: calc(55px - 20px);
				@media only screen and (max-width: 767px){
					width: calc(#{$small_title_push} - 20px);	
				}
			}
		}
	}
	.contact{ 
		background: #001a33;
		color: #fff;
		border-bottom: 1px solid #fff;
		padding: 80px 0 20px;
		.section-title{
			position:relative;
			display: table;
			margin:0 auto;
			padding:0 55px;
			padding:0  $title_push;
			@media only screen and (max-width: 767px){
				padding:0 $small_title_push;			
			}
			&:after{
				border-top: 1px solid;
				content: '';
				left: auto;
				right: 0;
				margin-top: .5em;
				position: absolute;
				width: calc(55px - 20px);
				@media only screen and (max-width: 767px){
					width: calc(#{$small_title_push} - 20px);	
				}
			}
		}
		.nav-lang{
			display: table;
			margin: 10px auto 20px;
			text-align: right;
			padding-bottom: 15px;
					
		}
		.language {
			float: right;
			
			.dropdown-menu{
					box-shadow: none;
					border-radius: 0;
			}
			
			+ .language{
				margin-right: 15px;
			}
			a {
				display: block;
				line-height: 1.1;
				border: 1px solid #fff;
				padding: 6px 7px;
				color: #fff;
				font-size: 14px;
				font-family: $Font_Bold;
				font-weight: 700;
				text-transform: uppercase;
				text-decoration: none;
				transition: all 0.3s ease-out;				
				@media only screen and (max-width: 991px){
					font-size: 14px;
				}
				@media only screen and (max-width: 767px){
					font-size: 12px;
				}
				svg{
					stroke:#fff;
					transition: all 0.3s ease-out;	
				    height: 12px!important;
				    width: 12px!important;
					
				}
				&:hover {
				  background: #fff;
				  color: #000;
				  svg{
						stroke:#001a33;
					}
				}
			}
			.dropdown-menu{
				border: 2px solid #fff;
				right: 0;
				left: auto;
				width: auto;
				min-width: auto;
				background: transparent;
				a {
					border: none;
					font-size: 14px;
				}
			}
		}	
	}
}