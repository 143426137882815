.offers-list{
	.more {
		font-size: 22px;
		font-weight: 400;
		line-height: 46px;
		margin-top: 45px;
		letter-spacing: 1px;
		display:table;
		text-decoration-line: none;
		padding-left:0px!important;
		@media only screen and (max-width: 991px){
			margin-top: 25px;
			font-size: 20px;
		}
		@media only screen and (max-width: 767px){
			margin-top: 25px;
			font-size: 18px;
			line-height: 1;
		}
		.fa {
		    position: relative;
		    font-size: 25px;
		    top: 1px;
		    margin-right: 5px;
		    color:$call_to_action;
		    @media only screen and (max-width: 767px){
				font-size: 18px;
			}
		}
		&:hover{
			color:$call_to_action;
		}
	}
	.section{
		padding:40px!important;
		@media only screen and (max-width:767px){
			padding:40px 0px!important;
		}
	}
	.section-bottom-title{
		margin: 0 0 16px!important;
		color: #000!important;
	}
	.btns-offer{
		padding-top:30px;
		li{
			width: 33.3333%;
			float: left;
		}
	}
	.cta-link{
		min-width: 100%;
		@media only screen and (max-width:1200px){
			padding:10px 0px;
		}

		@media only screen and (max-width:767px){
			font-size: 14px;
		}

	}
} 
.no-results-notice{
	display: block;
	padding-bottom:50px;
}

.no-results-btn .cta-link{
	min-width: 200px;
}


.vessel-details{
	#campaign_intro{
		max-width: 80%;
	    text-align: center;
	    padding: 0 0 50px;
	    margin: 0 auto;
	}
}