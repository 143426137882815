$Font_Regular		: 'Roboto Condensed', sans-serif;	//normal
$Font_Light 		: 'Roboto Condensed', sans-serif;	//200
$Font_Bold 			: 'Roboto Condensed', sans-serif;	//700
$Font_ExtraBold 	: 'Roboto Condensed', sans-serif;  	//900

$container_width: 1200px;
$container_fullwidth :100vw;
$container_smallwidth :900px;

$brand_color : rgba(0,26,51, 1);
$brand_color_2 : rgba(0,102,179, 1);
$call_to_action:#4A96D1;

$nav-scrolled-bg : #fff;
$nav-scrolled-links-color : rgba(0,0,0, 1);

$header_overlay : transparent;
$small_header_overlay : #fff;


$header_height:96px; 
$header_height_1600:94px; 
$header_height_991:75px; 
$header_height_767:70px; 

$title_push: 55px;
$small_title_push: 30px;

$video_overlay : linear-gradient(to bottom,rgba(0,0,0,.4),rgba(0,0,0,.3));
$image_overlay : linear-gradient(to bottom,rgba(0,0,0,.4),rgba(0,0,0,0));

@font-face {
    font-family: 'canter';
    src: url('../fonts/ac-canterbold-webfont.eot');
    src: url('../fonts/ac-canterbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ac-canterbold-webfont.woff2') format('woff2'),
         url('../fonts/ac-canterbold-webfont.woff') format('woff'),
         url('../fonts/ac-canterbold-webfont.ttf') format('truetype'),
         url('../fonts/ac-canterbold-webfont.svg#aka-acid-canterboldregular') format('svg');
    font-weight: normal;
    font-style: normal;

}