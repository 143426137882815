header{	
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
	transition: all 0.3s;
	position: fixed;
	background: $header_overlay;
	.top-menu {
		z-index: 10;
		-webkit-transition: all 0.1s ease-out;
		-moz-transition: all 0.1s ease-out;
		-ms-transition: all 0.1s ease-out;
		-o-transition: all 0.1s ease-out;
		transition: all 0.1s ease-out;
		-webkit-transform: translate3d(0, -10px, 0);
		-moz-transform: translate3d(0, -10px, 0);
		-ms-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0);
		opacity: 0;
		display: table;
    	width: 100%;
		&.visible {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
		
		.logo{
			display: table-cell;
			vertical-align: bottom;
			
			.symbol{
				display:none;
				line-height: 1;
			}

			.only_symbol{
				display: none;
			}
			
			@media only screen and (max-width: 1200px){
				display:table;
			}

			@media only screen and (max-width: 480px){
				position: relative;
				padding: 6px 0 0;

			}
			@media only screen and (max-width: 359px){
				top:0px;
				padding: 0;
			}
			
			@media only screen and (max-width: 380px){
				.full{
					display:none;
				}
				.symbol{
					display:table;	
				
				}	

				.only_symbol{
					display: none;
					padding: 0;
				}
					
			}

			@media only screen and (max-width: 359px){
				.symbol{
					display:none;	
				
				}	
				.only_symbol{
					display: table;
				}
			}
			
			svg {
				height: 67px;
				width: 320px;
				z-index: 9;
    			position: relative;
				.st0{fill:#0066B3;stroke: none;}
				.st1{fill:#DCDDDE;}
				.st2{fill:#000;}
				.st3{fill:#FFCB04;}
				.st4{fill:#DCB007;}				
				.stText{fill:#FFFFFF;}
				@media only screen and (max-width: 1024px){
					width: 280px;
				}	
				@media only screen and (max-width: 991px){
					width: 250px;
					height: 50px;
				}			
				@media only screen and (max-width: 767px){
					height: 45px;
					width: 220px;					
				}	
				@media only screen and (max-width: 480px){
					line-height: 1;
					height: 30px;
   					 width: 140px;
				}
				
				@media only screen and (max-width: 359px){
					height: 45px;
    				width: 45px;
				   .stText{
				   		display:none; 
				   }
				}
				
			}
		}		
		.pages{
			text-align: right;
			-webkit-transition: all .2s;
			transition: all .2s;
			position: relative;
			display: table-cell;
			vertical-align: bottom;
			padding: 0;
			padding-bottom: 15px;
			
			
			@media only screen and (max-width: 1800px){
				text-align: right;
				padding-right:30px;
				padding-bottom: 5px;
			}
			@media only screen and (max-width: 1300px){
			
			}
		
			@media only screen and (max-width: 991px){
				padding-right:0px;
			}
		
			> ul{
				margin: 0;
				justify-content: center;
				display: inline-block;
				text-align: center;
				
				> li {
					display:inline-block;
					padding-left: 26px;
					padding-bottom: 10px;
					
					.pages-group, 
					.subpages-li {
    					position: relative;
					}
				}
			}
			
			.menu-item {
				font-family: $Font_ExtraBold;
				font-size: 16px;
				text-transform: uppercase;
				padding: 0px 10px;
				@media only screen and (max-width: 1800px){
					padding: 0 8px ;
				}
				@media only screen and (max-width: 1700px){
					padding: 0 10px ;
				}
				@media only screen and (max-width: 1400px){
					font-size: 14px;
					padding: 0px 5px;
				}			
				@media only screen and (max-width: 1200px){
					padding: 0 5px;
				}				
				a {
					color:#fff;
					text-decoration: none;
					position: relative;
					outline: none!important;
					padding: 3px 0;
					font-weight: 400;
    				letter-spacing: 0.4px;
    				@media only screen and (max-width: 767px){
						color:$brand_color_2;
					}
					&:before{
						content: "";
						position: absolute;
						left: 50%;
						right: 50%;
						height: 2px;
						background-color: $call_to_action;
						bottom: 0;
						transition: all 0.3s;
					} 
					.fa{
						color:$call_to_action;
						@media only screen and (max-width: 767px){
							color:$brand_color_2;
						}
					}
					&.active,
					&:hover{
						/*color:#fff;
						@media only screen and (max-width: 767px){
							.fa{
								color:#fff;
							}
						}*/
						&:before{
							left: 25%;
							right: 25%;
						}
					}
					svg{ 
						height: 14px;
						width: 14px;
						line-height: 1;
						stroke:$call_to_action;
						fill:$call_to_action;
						@media only screen and (max-width: 767px){
							stroke:$brand_color_2;
						}
					}
				}
				.pages-group.hover{
					position:relative;
				}
				.pages-group.hover:hover{
					.dropdown-menu{
						display: block;
					}
				}
				.dropdown-menu{
					box-shadow: none;
					border-radius: 0;
					background: #fff;
					border:none;
					left: 50%;
				    transform: translateX(-50%);
				    min-width: 150px;
				    margin:0;
					.subpages{
						padding: 20px 15px 10px;
						.subpages-li{
							white-space: nowrap;
							text-transform: none;
						}
						a{
							font-weight: 500;
							font-size: 16px;
							letter-spacing: 0.4px;
							padding: 4px 0;
							display: table;
							margin:0 auto;
							text-align: center;
							color: #000;
						}
					}
				}
			}
			
		}


		
		.nav-lang{
			display: table-cell;
			vertical-align: bottom;
			text-align: right;

			@media only screen and (max-width: 991px){
				position: relative;
    			top: 0px;
				right:80px;
			}
			@media only screen and (max-width: 767px){
				top: -4px;
				a{
					padding: 6px 7px;
				}
			}
		}
		.language {
			float: right;
			.dropdown-menu{
					box-shadow: none;
					border-radius: 0;
			}
			
			+ .language{
				margin-right: 15px;
			}
			a {
				display: block;
				line-height: 1.1;
				border: 1px solid #fff;
				padding: 6px 7px;
				color: #fff;
				font-size: 14px;
				font-family: $Font_Bold;
				font-weight: 700;
				text-transform: uppercase;
				text-decoration: none;
				-webkit-transition: all 0.3s ease-out;
				-moz-transition: all 0.3s ease-out;
				-ms-transition: all 0.3s ease-out;
				-o-transition: all 0.3s ease-out;
				transition: all 0.3s ease-out;
				@media only screen and (max-width: 991px){
					font-size: 14px;
				}
				@media only screen and (max-width: 767px){
					font-size: 12px;
				}
				i{
					color:$call_to_action;
					@media only screen and (max-width: 767px){
						color:$brand_color_2;
					}
				}

				svg{ 
					height: 14px;
					width: 14px;
					stroke:$call_to_action;
					@media only screen and (max-width: 767px){
						stroke:$brand_color_2;
					}
				}


				&:hover {
				  background: #fff;
				  color: #000;
			  		i{
						@media only screen and (max-width: 767px){
						color:#fff;
						}
					}

				}
			}
			.dropdown-menu{
				border: 2px solid #fff;
				right: 0;
				left: auto;
				width: auto;
				min-width: auto;
				background: transparent;
				a {
					border: none;
					font-size: 14px;
				}
			}
		}	

		.hide-on-large{
			display: none;
			@media only screen and (max-width: 1800px){
				display: block;
			}
		}

		.display-on-large{
			@media only screen and (max-width: 1800px){
				display: none!important;
			}
		}

		.display-on-medium{
			@media only screen and (max-width: 1200px){
				display: none!important;
			}
		}	
	}
	
	.burger{
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		width: 33px;
    	height: 22px;
    	@media only screen and (max-width: 340px){
			right: 15px;
		}
		
		&.hidden{
			display: none!important;
		}
		
		.line{
			position: absolute;
			right: 0;
			width: 100%;
			height: 2px;
			background: #fff;
			transition: all 0.3s;
			&.line-1{
				top: 0;
			}
			&.line-2{
				top: 50%;
				transform: translateY(-50%);
			}
			&.line-3{
				top: 100%;
				margin-top: -2px;
			}
		}
	}
	
	@media only screen and (max-width: 767px){
		background: $small_header_overlay;
		border-bottom: 1px solid $brand_color;
		.top-menu {
			padding-left: 10px!important;
			.logo{
				svg {
					.st0{fill:#0066B3;}
					.st1{fill:#DCDDDE;}
					.st2{fill:#231F20;}
					.st3{fill:#FFCB04;}
					.st4{fill:#DCB007;}
					.stText{fill:#000000;}
				}
			}	
			.nav-lang{
				top: 7px;
				right:45px;
				a{
					display: table;
				}
			}
			.pages{
				padding: 0;
			}	
			.nav-lang,	
			.language{
				a{
					border: 1px solid $brand_color_2;
					color: $brand_color_2;
					padding:6px 7px!important;
					&:hover {
					  background: $brand_color_2;
					  color: #fff;
					  svg{
					  	 stroke: $call_to_action;
					  }
					 
					  &:before{
					  	display: none!important;
					  }
					}
				}
			}
			
			.burger {
				.line{
					background: $brand_color_2;
				}
			}
			
		}
	}
}
header,
.show-responsive header,
.no-hero header,
header.sticky{
	background: $small_header_overlay;
	border-bottom: 1px solid #f2f3f6;
	.top-menu {
		.logo{
			svg {
				.st0{fill:#0066B3;}
				.st1{fill:#DCDDDE;}
				.st2{fill:#231F20;}
				.st3{fill:#FFCB04;}
				.st4{fill:#DCB007;}
				.stText{fill:#000000;}
			}
		}	
		.pages{
			.menu-item {
				a {
					color: #000;
					&:before{
						background-color: $call_to_action;
					}
					svg{
						stroke:$call_to_action;
						@media only screen and (max-width: 767px){
							stroke: $brand_color_2;
						}
						
					}
					&:hover {
					 	 svg{
							@media only screen and (max-width: 767px){
								stroke: #fff;
							}							
						}
					}
				}
			}
		}
		.language{
			a{
				border: 1px solid #000;
				color: #000;
				&:hover {
				  background: $brand_color_2;
				  color: #fff;

				 	 svg{
						@media only screen and (max-width: 767px){
							stroke: #fff;
						}
						
					}
				}

			}
		}
		.burger {
			.line{
				background: #000;
			}
		}
	}
} 
.responsive-nav-overlay{
	position: fixed;
	right: 0;
	bottom: 0;
	width: 100%;
	z-index: 80;
	opacity: 0;
	pointer-events: none;
	background: rgba(0,0,0,0.3);
	transition: all 0.3s;
	top:$header_height;
	@media only screen and (max-width: 1600px){
		top:$header_height_1600;
	}
	@media only screen and (max-width: 991px){
		top:$header_height_991;
	}
	@media only screen and (max-width: 767px){
		top:$header_height_767;
	}
	
}
.responsive-nav{
	position: fixed;
	right: 0;
	height: 100%;
	bottom: 0;
	width: 280px;
	z-index: 900;
	background: #fff;
	transition: all 0.3s;
	transform: translateX(100%);
	border-top: 1px solid $brand_color;
	padding: 30px;
	top:$header_height;
	@media only screen and (max-width: 1600px){
		top:$header_height_1600;
	}
	@media only screen and (max-width: 991px){
		top:$header_height_991;
	}
	@media only screen and (max-width: 767px){
		top:$header_height_767;
	}
	ul{
		margin: 0;
		width: 100%;
	} 

	.social-item{
		padding-bottom: 15px;
		padding-right:15px;
		a{
			color:#0066b3;
			display: table;
		}
		
	} 
	.cta-link{
		margin-top: 30px;
		width: 100%;
		max-width: 250px;
	    min-width: auto;
	    .fa{
	    	color: $call_to_action;
	    }
	}
	.menu-item {
		text-align: left;
		a {
			color: $brand_color;
			text-decoration: none;
			position: relative;
			outline: none !important;
			padding: 3px 0;
			padding: 10px 0;
			display: table;
			font-size: 16px;
			text-transform: uppercase;
			
			&:before{
				content: "";
				position: absolute;
				left: 0;
				right: 100%;
				height: 2px;
				background-color: $call_to_action;
				bottom: 0;
				transition: all 0.3s;
			}

			&.active,
			&:hover{
				&:before{
					right: 50%;
				}
			}
		}
	}
}
.mobile-dropdown-menu{
	
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background: #fff;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    list-style: none;
    max-height: initial;
}
.opened .mobile-dropdown-menu ,
.open .mobile-dropdown-menu {
    max-height: 800px;
    overflow: hidden;
}
.show-responsive{
	@media only screen and (max-width: 1200px){
		.responsive-nav-overlay{
			opacity: 1;
			pointer-events: all;
		}
		.responsive-nav{
			transform: translateX(0);
		}

		
		header{
			background: #fff;
    		border-bottom: 1px solid #f2f3f6;
			.burger{
				.line{
					background: #000;
					&.line-1{
						transform: rotate(45deg);
						top: 10px;
					}
					&.line-2{
						opacity: 0;							
					}
					&.line-3{
						transform: rotate(-45deg);
						top: 12px;
					}
				}
			}
			.top-menu {
				.logo{
					svg {
						.st0{fill:#0066B3;}
						.st1{fill:#DCDDDE;}
						.st2{fill:#231F20;}
						.st3{fill:#FFCB04;}
						.st4{fill:#DCB007;}
						.stText{fill:#000000;}
					}
			   }
				.language{
					a{
						border: 1px solid #000;
						color: #000;
						&:hover {
						  background: $brand_color_2;
						  color: #fff;
						}

					}
				}
			
			}
		}
	}
}

.main-nav-capmaign {
    .nav-lang {
    	vertical-align: middle!important;
    	 svg {
			    height: 12px!important;
			    width: 12px!important;
			}
		@media only screen and (max-width: 1200px){
		    top: 0px!important;
		    position:relative;
		    right: 80px!important;
		}
		@media only screen and (max-width: 767px){
		    right: 60px!important;
		   
		}
		@media only screen and (max-width: 480px){
		    right: 45px!important;
		}


		
	}
	.logo{
		vertical-align: middle!important;
		@media only screen and (max-width: 1024px){
			 svg {
			    width: 190px!important;
			}
		}
		@media only screen and (max-width: 1024px){
			 svg {
			    width: 190px!important;
			}
		}

		@media only screen and (max-width: 767px){
			 svg {
			    width: 120px!important;
			}
		}

		@media only screen and (max-width: 480px){
			 svg {
			    width: 90px!important;
			}
		}
			
	}
	.pages {
		padding: 0!important;
	    vertical-align: middle!important;
	}

}
