.fleet-grid {
    a.grid-item.bg-cover {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        .caption {
            .description {
                font-size: 16px;

                @media only screen and (max-width: 1400px) {
                    font-size: 14px;
                }

                @media only screen and (max-width:767px) {
                    font-size: 12px;
                }
            }
        }
    }

    a.grid-item.bg-cover:hover {
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
        transform: scale(1.05);
        -moz-box-shadow: 1px 1px 15px 1px #656565;
        -webkit-box-shadow: 1px 1px 15px 1px #656565;
        -o-box-shadow: 1px 1px 15px 1px #656565;
        box-shadow: 1px 1px 15px 1px #656565;
        filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=134, Strength=15);
        z-index: 99;
    }

    .mask-v {
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 40%, rgba(0, 0, 0, 0.6) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(40%, rgba(153, 153, 153, 0)), color-stop(100%, rgba(0, 0, 0, 0.6)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 40%, rgba(0, 0, 0, 0.6) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 40%, rgba(0, 0, 0, 0.6) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 40%, rgba(0, 0, 0, 0.6) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 40%, rgba(0, 0, 0, 0.6) 100%);

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .vessel-available,
    .mega-experience {
        padding: 30px 5px 5px;
        background: #fff;
        -webkit-box-shadow: 0px 10px 47px -9px rgba(0, 0, 0, 0.64);
        -moz-box-shadow: 0px 10px 47px -9px rgba(0, 0, 0, 0.64);
        box-shadow: 0px 10px 47px -9px rgba(0, 0, 0, 0.64);

        @media only screen and (max-width: 767px) {
            padding: 20px 5px 5px;
        }

        img {
            max-width: 120px;
            display: block;

            @media only screen and (max-width: 1400px) {
                max-width: 70px;
            }

            @media only screen and (max-width: 991px) {
                max-width: 60px;
            }

            @media only screen and (max-width: 767px) {
                max-width: 50px;
            }

            @media only screen and (max-width: 480px) {
                max-width: 40px;
            }
        }


    }

    .vessel-available {
        padding: 20px 5px 5px;

        @media only screen and (max-width: 767px) {
            padding: 10px 5px 5px;
        }

        img {
            max-width: 70px;
            display: block;

            @media only screen and (max-width: 1200px) {
                max-width: 50px;
            }

            @media only screen and (max-width: 767px) {
                max-width: 40px;
            }
        }
    }
}

.fleet-list {
    padding: 50px 0;

    .list-grid-item {
        padding: 17px 0;

        .section {
            padding: 65px 0px !important;

            @media only screen and (max-width: 767px) {
                padding: 30px 0px 0px !important;
            }

            .content {}

        }

        .pic {
            background: $brand_color;
            overflow: hidden;

            img {
                transition: all 1s;
            }
        }

        .bg-cover {
            position: relative;
            background-size: cover;
            background-position: center center;
            min-height: 400px;
            transition: all 1s;

            @media only screen and (max-width: 767px) {
                min-height: 300px;
            }
        }

        .bg-overlay {
            overflow: hidden;
            display: block;
        }

        &:hover {
            .bg-cover {
                transform: scale(1.5);
                opacity: 0.8;
            }


        }

        .list-title {
            font-size: 2em;
            letter-spacing: 0.8px;
            line-height: 1;
            color: $brand_color;
            padding: 5px 0 20px 0;
            text-decoration-line: none;
            display: block;

            @media only screen and (max-width: 1200px) {
                font-size: 1.8em;
            }

        }

        .employment {
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.5px;
            color: #808080;

            @media only screen and (max-width: 1200px) {
                font-size: 20px;
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
        }

        .caption {
            padding: 0 0 15px;

            .description {
                font-size: 17px;
                letter-spacing: 0.5px;

                @media only screen and (max-width: 1200px) {
                    font-size: 16px;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .more {
                font-size: 20px;
                font-weight: 400;
                line-height: 46px;
                margin-top: 20px;
                letter-spacing: 1px;
                display: table;
                text-decoration-line: none;
                padding-left: 0px !important;

                @media only screen and (max-width: 991px) {
                    font-size: 20px;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 18px;
                    line-height: 1;
                }

                .fa {
                    position: relative;
                    font-size: 25px;
                    top: 1px;
                    margin-right: 5px;
                    color: $call_to_action;

                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                    }
                }

                &:hover {
                    color: $call_to_action;
                }
            }
        }
    }
}

.fleet-hero {
    min-height: 400px;

    .fleet-hero-title {
        background: transparent !important;
        padding: 0 60px !important;
        max-width: 1500px !important;
        width: 100% !important;
        padding: 0 15px !important;
        margin: 0 auto;
        top: 50% !important;
        z-index: 1;
        transform: translateY(-50%);
        bottom: auto !important;

        @media only screen and (max-width: 767px) {
            bottom: 20% !important;
            top: auto !important;
            transform: translateY(0%);
        }

        @media only screen and (max-width: 767px) {
            bottom: 5% !important;
        }

        .main-title {
            margin: 0 !important;
            color: #fff !important;
            text-align: left !important;

            svg {
                fill: #fff !important;
            }
        }

    }

    .video-link {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: auto;

    }
}

.vessel-details {

    .section {
        padding: 60px 40px !important;

        @media only screen and (max-width: 1200px) {
            padding: 60px 10px !important;
        }

        @media only screen and (max-width: 991px) {
            padding: 60px 0px !important;
        }

        @media only screen and (max-width: 767px) {
            padding: 30px 0px !important;
        }

        &.section-top-header {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        .content {
            position: relative;
            max-width: 850px;
            padding: 0 15px;
            margin: 0 auto;
            display: table;
        }

        .section-top-title {
            text-align: center;
            padding-left: 0;
        }

        .section-title {
            text-align: center;
            padding-left: 0;

            &:before {
                display: none;
            }
        }
    }

    .album-counter {
        position: absolute;
        right: 15px;
        bottom: 15px;
        background: rgba(0, 0, 0, .5);
        border: 1px solid #fff;
        padding: 10px;
        line-height: 1;
        z-index: 5;
        font-family: $Font_Light !important;
    }

    .vessel-equipment {
        position: relative;

        &.container-fluid {
            @media only screen and (max-width: 991px) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .bg-cover {
            position: absolute;
            height: 100%;
            min-height: 100%;

            @media only screen and (max-width: 991px) {
                position: relative;
                min-height: 40vh;
            }

            @media only screen and (max-width: 700px) and (max-height: 700px) {
                min-height: 350px;
            }
        }

        &.all-equipment {

            .section-title+div,
            .section-title+div+div {
                @media only screen and (max-width: 767px) {
                    padding-left: 15px;
                }

            }
        }

        &.main-equipment {
            .section-content {
                @media only screen and (max-width: 767px) {
                    ul {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }

    .row {
        margin: 0;

        label {
            width: 10em;
        }

        .spec {
            padding: 10px 0;
            border-bottom: 1px solid #f2f3f6;
        }
    }

    .vessel-layout {
        &.container-fluid {
            @media only screen and (max-width: 991px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .vessel-about {
        padding: 43px 0 !important;

        @media only screen and (max-width: 1200px) {
            padding: 35px 0 !important;
        }

        @media only screen and (max-width: 991px) {
            padding: 30px 0 !important;
        }

        @media only screen and (max-width: 767px) {
            padding: 25px 0 !important;
        }

        .employment {
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.5px;
            color: #808080;

            @media only screen and (max-width: 1200px) {
                font-size: 20px;
            }

            @media only screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
        }

        .description {
            color: #808080;
        }

        .main-title {
            font-size: 2.1em;
            margin: 0.27em 0;
            letter-spacing: 1.1px;
            font-weight: 300;

            @media only screen and (max-width: 767px) {
                font-size: 2em;
            }
        }

        .intro_text {
            padding: 43px 0 20px;

            @media only screen and (max-width: 1200px) {
                padding: 35px 0 20px;
            }

            @media only screen and (max-width: 991px) {
                padding: 25px 0 15px;
            }

            @media only screen and (max-width: 767px) {
                padding: 15px 0 10px;
            }
        }
    }

    .vessel-album {
        .section-top-header {
            max-width: 1150px;
            margin: 0 auto;
            text-align: center;
            padding: 60px 15px 12px !important;

            @media only screen and (max-width: 1200px) {
                padding: 40px 15px 10px !important;
            }

            @media only screen and (max-width: 991px) {
                padding: 30px 15px 10px !important;
            }

            @media only screen and (max-width: 767px) {
                padding: 20px 15px 10px !important;
            }

            .section-top-title {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.7px;
                text-transform: none;
                color: #808080;

                @media only screen and (max-width: 991px) {
                    font-size: 18px;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .section-title {
                font-size: 38px;
                padding: 10px 0;
                text-transform: none;

                @media only screen and (max-width: 1200px) {
                    font-size: 34px;
                }

                @media only screen and (max-width: 991px) {
                    font-size: 28px;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 26px;
                }
            }

            .section-bottom-title {
                color: #808080;
                font-size: 18px;
                margin: 0;
                text-transform: none;
                text-align: center;

                @media only screen and (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }

        .col {
            padding: 25px;

            @media only screen and (max-width: 1200px) {
                padding: 20px;
            }

            @media only screen and (max-width: 991px) {
                padding: 15px;
            }

            @media only screen and (max-width: 767px) {
                padding: 10px;
            }
        }

        a {
            &.gallery-item.bg-cover {
                font-family: 'piconsthin' !important;
                speak: none;
                color: #ffffff;
                text-align: center;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-transition: all .2s ease-in-out;
                /* Safari and Chrome */
                -moz-transition: all .2s ease-in-out;
                /* Firefox */
                -o-transition: all .2s ease-in-out;
                /* Opera */
                -ms-transition: all .2s ease-in-out;
                /* IE 9 */
                transition: all .2s ease-in-out;
                display: block;
                width: 100%;
                height: 35vh;

                &:hover {
                    -webkit-transform: scale(1.1);
                    /* Safari and Chrome */
                    -moz-transform: scale(1.1);
                    /* Firefox */
                    -ms-transform: scale(1.1);
                    /* IE 9 */
                    -o-transform: scale(1.1);
                    /* Opera */
                    transform: scale(1.1);
                    -moz-box-shadow: 1px 1px 15px 1px #656565;
                    -webkit-box-shadow: 1px 1px 15px 1px #656565;
                    -o-box-shadow: 1px 1px 15px 1px #656565;
                    box-shadow: 1px 1px 15px 1px #656565;
                    filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=134, Strength=15);
                    z-index: 99;
                }
            }

            &:focus::before,
            &:hover::before,
            &:active::before {
                content: "\e989";
                font-size: 40px;
                position: absolute;
                z-index: 3;
                margin-left: -15px;
                top: 50%;
                margin-top: -20px;
            }

            &:focus::after,
            &:hover::after,
            &:active::after {
                content: "";
                background: rgba(0, 0, 0, 0.3);
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: 1;
            }
        }
    }

}

.full-box-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.fancybox-overlay {
    background: rgba(0, 0, 0, 0.7)
}



.fleet_types {
    padding: 0;
    min-height: calc(100vh - #{$header_height});

    @media only screen and (max-width: 1600px) {
        min-height: calc(100vh - #{$header_height_1600});
    }

    @media only screen and (max-height: 700px) and (min-width: 1201px) {
        min-height: 600px;
    }

    @media only screen and (max-height: 700px) and (max-width: 1200px) {
        min-height: 500px;
    }

    @media only screen and (max-height: 700px) and (max-width: 767px) {
        min-height: 350px;
    }

    .fleet_types_item {
        width: 50%;
        float: left;
        position: relative;
        min-height: calc(100vh - #{$header_height});

        @media only screen and (max-width: 1600px) {
            min-height: calc(100vh - #{$header_height_1600});
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
            min-height: 350px;
        }

        @media only screen and (max-height: 700px) and (min-width: 1201px) {
            min-height: 600px;
        }

        @media only screen and (max-height: 700px) and (max-width: 1200px) {
            min-height: 500px;
        }

        @media only screen and (max-height: 700px) and (max-width: 767px) {
            min-height: 360px;
        }

        &:hover {
            .type-title {
                background: $call_to_action;
            }
        }

        .full-box-link {
            z-index: 1;
        }
    }

    .background {
        width: 100%;
        right: auto;
        display: block;
        height: calc(100% - 80px);
        top: auto;
        background-position: center calc(50% + 30px);

        @media only screen and (max-width: 1200px) {
            height: calc(100% - 65px);
        }

        @media only screen and (max-width: 991px) {
            height: calc(100% - 55px);
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
            height: calc(100% - 55px);
            background-position: center calc(50% + 10px);
        }

        &:nth-child(2) {
            left: auto;
            right: 0;
        }

    }

    .type-title {
        color: #fff;
        font-family: $Font_Light;
        opacity: initial;
        z-index: 10;
        font-weight: 300;
        font-size: 28px;
        letter-spacing: 2.8px;
        position: relative;
        display: block;
        left: 0;
        /* right: 0; */
        background: #fff;
        width: 100%;
        color: #000;
        text-align: center;
        background: #dbdcde;
        padding: 16px 5px 18px 5px;

        @media only screen and (max-width: 1200px) {
            font-size: 26px;
        }

        @media only screen and (max-width: 991px) {
            font-size: 22px;
        }

        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }

        .type-title-block {
            display: table;
            margin: 0 auto;
        }

        .icon {
            float: left;
            padding-right: 22px;
            margin-right: 22px;
            border-right: 1px solid #fff;
            line-height: 1;

            svg {
                height: 80px;
                width: 80px;

                @media only screen and (max-width: 1200px) {
                    height: 60px;
                    width: 60px;
                }

                @media only screen and (max-width: 991px) {
                    height: 50px;
                    width: 50px;
                }

            }
        }

        .title {
            font-weight: 300;
            font-size: 35px;
            display: inline-block;
            line-height: 80px;
            letter-spacing: 1px;
            float: left;
            vertical-align: middle;

            @media only screen and (max-width: 1200px) {
                font-size: 30px;
                line-height: 60px;
            }

            @media only screen and (max-width: 991px) {
                font-size: 28px;
                line-height: 50px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }
        }

    }

    .container,
    .top-menu {
        z-index: 2;
    }

    .container {
        vertical-align: bottom;
    }

    .bg-container {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        >div {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
            -webkit-transition: all 2s ease-out;
            -moz-transition: all 2s ease-out;
            -ms-transition: all 2s ease-out;
            -o-transition: all 2s ease-out;
            transition: all 2s ease-out;
            opacity: 0;

            &.active {
                opacity: 1;
                -webkit-animation: intro_slideshow 8s infinite;
                -o-animation: intro_slideshow 8s infinite;
                animation: intro_slideshow 8s infinite;

                &.slower {
                    -webkit-animation-duration: 12s;
                    -o-animation-duration: 12s;
                    animation-duration: 12s;
                }
            }
        }
    }

    .overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $image_overlay;
        pointer-events: none;
    }

    .video-link {
        position: absolute;
        left: 50%;
        top: 50%;
        bottom: auto;
        transform: translate(-50%, -50%);
    }
}



.full-box-link .hov-line-1 {
    height: 2px;
    width: 0%;
    right: 0;
    top: 0;
    -webkit-transition: width .15s linear;
    transition: width .15s linear;
    -webkit-transition-delay: .0s;
    transition-delay: .0s;
}

.full-box-link .hov-line-2 {
    height: 0%;
    width: 2px;
    right: 0;
    bottom: 0;
    -webkit-transition: height .15s linear;
    transition: height .15s linear;
    -webkit-transition-delay: .15s;
    transition-delay: .15s;
}

.full-box-link .hov-line-3 {
    height: 2px;
    width: 0%;
    right: 0;
    bottom: 0;
    -webkit-transition: width .15s linear;
    transition: width .15s linear;
    -webkit-transition-delay: .15s;
    transition-delay: .15s;
}

.full-box-link .hov-line-4 {
    height: 0%;
    width: 2px;
    left: 0;
    bottom: 0;
    -webkit-transition: height .15s linear;
    transition: height .15s linear;
    -webkit-transition-delay: .0s;
    transition-delay: .0s;
}

.full-box-link .hov-line {
    position: absolute;
    background: #fff;
}

.yacht-sales-container {
    overflow-y: auto;

    .sales-header {
        padding: 30px 15px;
        font-weight: 300;
        font-size: 28px;
        letter-spacing: 2.8px;
        display: block;
        color: #000;
        text-align: center;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        width: 100%;

        @media only screen and (max-width: 767px) {
            padding: 5px 15px;
        }

        svg {
            width: 400px;

            .st0 {
                fill: #0066B3;
            }

            .st1 {
                fill: #fff;
            }

            .st2 {
                fill: #231F20;
            }

            .st3 {
                fill: #FFCB04;
            }

            .st4 {
                fill: #DCB007;
            }

            .stText {
                fill: #fff;
            }

            @media only screen and (max-width:767px) {
                width: 300px;
            }

            @media only screen and (max-width:480px) {
                width: 250px;
            }
        }

        .type-title-block {
            display: table;
            margin: 0 auto;
        }

        .icon {
            float: left;
            padding-right: 22px;
            margin-right: 22px;
            border-right: 1px solid #fff;
            line-height: 1;

            svg {
                height: 80px;
                width: 80px;

                @media only screen and (max-width: 1200px) {
                    height: 60px;
                    width: 60px;
                }

                @media only screen and (max-width: 991px) {
                    height: 50px;
                    width: 50px;
                }

            }
        }

        .title {
            font-weight: 300;
            font-size: 35px;
            display: inline-block;
            line-height: 80px;
            letter-spacing: 1px;
            float: left;
            vertical-align: middle;

            @media only screen and (max-width: 1200px) {
                font-size: 30px;
                line-height: 60px;
            }

            @media only screen and (max-width: 991px) {
                font-size: 28px;
                line-height: 50px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }
        }
    }

    .fleet_types {
        min-height: 100vh;

        @media only screen and (max-width: 767px) {
            min-height: 100%;
        }

        .charter-title {
            position: absolute;
            left: 50%;
            top: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 10;
            color: #fff;
            margin: 0;
            padding-bottom: 150px;
            width: 100%;
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;

            dd {
                font-size: 18px;
            }

            span {
                display: block;
                color: #ffcb04;
                padding-bottom: 15px;
            }
        }

        .type-title {
            padding: 0;
            top: 83px;
            background: rgba(0, 0, 0, .5);
            color: #fff;

            .title {
                font-weight: 300;
                font-size: 26px;
                line-height: 50px;
                letter-spacing: 1px;
                vertical-align: middle;
            }
        }

        .fleet_types_item {
            min-height: 100vh;
            float: right;

            @media only screen and (max-width: 767px) {
                min-height: 600px;
                float: left;
            }

            .full-box-link {
                z-index: 11;
            }

            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, .5);
                pointer-events: none;
                transition: all ease-in-out .3s;
            }

            .hero-label {
                position: absolute;
                left: 50%;
                top: 50%;
                border: 2px solid #fff;
                z-index: 10;
                color: #fff;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                font-weight: 500;
                width: 100%;
                max-width: 250px;
                text-align: center;
                transition: all ease-in-out .3s;

                a {
                    padding: 10px;
                    color: #fff;
                    display: block;
                    text-decoration: none;
                    transition: all ease-in-out .3s;
                }
            }

            .background {
                height: 50%;
                background-position: center calc(50% + 0px);

                &.up {
                    top: 0px;
                }

                .full-box-link {
                    z-index: 3;
                }

                .logo-sales {
                    min-width: 70%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    padding: 20px 25px;
                    text-align: center;
                    background: rgba(0, 0, 0, 0);
                    transition: all ease-in-out .3s;
                    padding-right: 50px;

                    @media only screen and (max-width: 1200px) {
                        min-width: 90%;
                    }

                    @media only screen and (max-width: 767px) {
                        padding: 10px 15px;
                        padding-right: 40px;
                    }

                    h3 {
                        display: block;
                        margin: 0;
                        color: #ffcb04;
                        padding-bottom: 15px;
                    }

                    svg {
                        width: 270px;
                        display: table;
                        margin: 0 auto;
                        padding-bottom: 15px;
                        height: 70px;

                        @media only screen and (max-width: 767px) {
                            width: 200px;
                            height: 50px;
                        }
                    }

                    .logo-sales-motto {
                        color: #fff;
                        text-align: center;
                        font-weight: 500;
                        display: block;
                    }

                    .logo-sales-promo {
                        color: #000;
                        text-align: center;
                        font-weight: 500;
                        display: block;
                        background-color: #ffcb04;
                        padding: 10px;
                        max-width: 80%;
                        margin: 10px auto 0;
						line-height: 1;
						font-weight: 700;
						letter-spacing: -.2px;
                    }

                    .logo-sales-arrow {
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);

                        svg {
                            fill: #fff;
                            width: 60px;
                            padding-bottom: 0;

                            @media only screen and (max-width: 991px) {
                                width: 50px;
                            }

                            @media only screen and (max-width: 767px) {
                                width: 40px;
                            }
                        }
                    }

                }

                &:hover {
                    .overlay {
                        background: rgba(0, 0, 0, 0);
                    }

                    .logo-sales {
                        background: rgba(0, 0, 0, .5);
                    }


                    .hov-line-1 {
                        -webkit-transition-delay: .15s;
                        transition-delay: .15s;
                        width: 100%;
                    }

                    .hov-line-2 {
                        -webkit-transition-delay: .0s;
                        transition-delay: .0s;
                        height: 100%;
                    }


                    .hov-line-3 {
                        -webkit-transition-delay: .0s;
                        transition-delay: .0s;
                        width: 100%;
                    }

                    .hov-line-4 {
                        -webkit-transition-delay: .15s;
                        transition-delay: .15s;
                        height: 100%;
                    }



                }

                @media only screen and (max-width: 767px) {
                    .overlay {
                        background: rgba(0, 0, 0, 0);
                    }

                    .logo-sales {
                        background: rgba(0, 0, 0, .5);
                    }


                    .hov-line-1 {
                        -webkit-transition-delay: .15s;
                        transition-delay: .15s;
                        width: 100%;
                    }

                    .hov-line-2 {
                        -webkit-transition-delay: .0s;
                        transition-delay: .0s;
                        height: 100%;
                    }


                    .hov-line-3 {
                        -webkit-transition-delay: .0s;
                        transition-delay: .0s;
                        width: 100%;
                    }

                    .hov-line-4 {
                        -webkit-transition-delay: .15s;
                        transition-delay: .15s;
                        height: 100%;
                    }
                }
            }

            &:hover {

                .hero-label {
                    background-color: #4A96D1;
                    border: 2px solid #4A96D1;
                    color: #000;
                }
            }

            @media only screen and (max-width: 767px) {
                .hero-label {
                    background-color: #4A96D1;
                    border: 2px solid #4A96D1;
                    color: #000;
                }
            }

        }
    }
}

.sales-banner {
    padding-top: 30px;

    .sales-banner-item {
        min-height: 120px;
        position: relative;
        margin-bottom: 30px;
        background: #00507F;
        transition: all ease-in-out .3s;

        &:hover {
            background: $brand_color;
        }

        .full-box-link {
            z-index: 1;
        }

        .logo-sales {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: table;
            text-align: center;
            width: 100%;
            padding: 0 60px;

            @media only screen and (max-width: 767px) {
                padding: 0 40px;
            }

            svg {
                width: 300px;
                display: table;
                margin: 0 auto;
                height: 70px;

                @media only screen and (max-width: 767px) {
                    width: 200px;
                }

            }

            .logo-sales-motto {
                color: #fff;
                padding-top: 10px;
                font-weight: 500;
                display: block;
                font-size: 14px;
            }

            .logo-sales-arrow {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);

                @media only screen and (max-width: 767px) {
                    right: 0px;
                }

                svg {
                    fill: #fff;
                    width: 50px;
                    padding-bottom: 0;

                    @media only screen and (max-width: 767px) {
                        width: 30px;
                    }
                }
            }
        }
    }
}

body.open-sales-charter {
    overflow: hidden;
}

.dufour-yachts-promo {

    .dufour-yachts-promo-title {
        font-size: 2.1em;
        margin: 0.27em 0;
        letter-spacing: 1.1px;
        font-weight: 300;
        text-align: center;

        @media only screen and (max-width: 767px) {
            font-size: 2em;
        }
    }

    .subtitle {
       color: grey;
	    font-size: 22px;
	    letter-spacing: 2px;
	    margin: 0;
	    text-transform: none;
	    display:block;
	    text-align: center;
        font-weight: 400;
    }
    .contact-list-inline{
    	text-align: center;
    	li{
    		border-right: none;
    		padding-bottom: 10px;
    		a{
    			min-width: 240px;
    			font-size: 20px;
    			text-align: center;
    			color:#fff;
    			background-color: #0066b3;
			    padding: 10px 15px!important;
			    transition: all .3s; 
			    font-weight: 400;
    			letter-spacing: .4px;
    			display: block;
    			&:hover{
    				background-color: #001a33;
    			}
    			 @media only screen and (max-width: 767px) {
		            font-size: 18px;
		        }
    		}
    		
    	}
    }
    .dufour-promo-item{
    	padding: 30px 0;
    	 @media only screen and (max-width: 1200px) {
            padding: 15px 0;
        }
        .bg-link{
        	display: block;
        	position:relative;
        	&:after{
        		content: "";
        		left: 0;
        		right:0;
        		top:0;
        		bottom:0;
        		position:absolute;
        		background:rgba(0,0,0,.5);
        		transition:all .3s;
        		opacity:0;
        		z-index: 1;
        	}

        	.promo-cta{
        		position:absolute;
        		top: 50%;
        		left:50%;
        		z-index:0;
        		transform:translate(-50%,-70%);
        		text-align: center;
    			color:#000;
    			background-color: #4A96D1;
			    padding: 10px 15px!important;
			    transition: all .3s; 
			    text-decoration:none;
			    font-weight: 400;
    			letter-spacing: .4px;
    			display: table;
    			opacity:0;
        	}
        	&:hover{
        		&:after{
        			opacity:1;
        		}
        		.promo-cta{
        			z-index:3;
        			opacity:1;
        			transform:translate(-50%,-50%);
        		}
        		.full-box-link{
        			z-index:2;
        		}
        	}

        }
    	.dufour-promo-item-details{
    		.dufour-promo-item-title{
    			font-size: 2em;
	            letter-spacing: 0.8px;
	            line-height: 1;
	            color: $brand_color;
	            padding: 5px 0 20px 0;
	            text-decoration-line: none;
	            display: block;
	            text-align: center;
	            @media only screen and (max-width: 1200px) {
	                font-size: 1.8em;
	            }
    		}
    		.cta-link{
    			display: table;
    			margin: 0 auto 20px;
    		}

    		.dufour-promo-item-info{
    			font-size: 24px;
    			 display: block;
    			 text-align: center;
    			letter-spacing: .5px;
    			padding-bottom: 15px;
	            @media only screen and (max-width: 767px) {
	                font-size: 18px;
	            }
    		}
    	}
    }

     

}