.bg-grey {
    background: #151515;
    color: #fff;
}
.list_supages {
    padding: 50px 0;
}
.homepage-moto {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 400;
    padding: 50px 15px 0;
    @media only screen and (max-width: 991px) {
        padding:40px 15px 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 767px) {
        padding: 30px 15px 0;
        font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
        padding: 20px 15px 0;
    }
}
.grid_section_title {
    font-family: $Font_ExtraBold;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    padding-top: 20px;
    @media only screen and (max-width: 991px) {
        font-size: 22px;
        padding-top: 20px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 20px;
        padding-top: 10px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 18px;
        padding-top: 10px;
    }
    .icon-title {
        display: block;
        float: left;
        border-right: 1px solid #fff;
        margin-right: 15px;
        padding-right: 10px;
        line-height: 1;
        width: 73px;
        fill: #fff;
        @media only screen and (max-width: 767px) {
            margin-right: 10px;
            width: 55px;
        }
        @media only screen and (max-width: 480px) {
            width: 45px;
        }
        @media only screen and (max-width: 380px) {
            display: none;
        }
        svg {
            width: 62px;
            height: 62px;
            @media only screen and (max-width: 767px) {
                width:44px;
                height: 44px;
            }
            @media only screen and (max-width: 480px) {
                width: 40px;
                height: 40px;
            }
        }
    }
    .grid-top-title {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 300;
        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
    .grid-title {
        font-size: 44px;
        margin: 0px 0px 10px;
        font-style: normal;
        font-weight: 300;
        @media only screen and (max-width: 991px) {
            font-size: 36px;
            line-height: 56px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 40px;
        }
        @media only screen and (max-width: 480px) {
            font-size: 18px;
            line-height: 38px;
        }
        @media only screen and (max-width: 380px) {
            font-size: 17px;
        }
    }
}
.grid_sub_section_title {
    font-family: $Font_ExtraBold;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 10px 0px;
    @media only screen and (max-width: 991px) {
        font-size: 22px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 18px;
    }
}
#about {
    &.section {
        padding: 30px 60px 20px;
        @media only screen and (max-width: 991px) {
            padding: 20px 20px 10px;
        }
    }
    p {
        &.animate {
            &.cto {
                font-size: 20px;
                font-weight: 700;
                @media only screen and (max-width: 991px) {
                    font-size: 18px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
            &.heading {
                font-size: 64px;
                font-family: $Font_ExtraBold;
                color: $brand_color;
                text-align: right;
                margin: 0;
                margin-top: 40%;
                transform: translateY(-50%);
                padding: 0 25px 0 0;
                @media (max-width: 1200px) {
                    font-size: 60px;
                }
                @media (max-width: 991px) {
                    font-size: 54px;
                }
                @media (max-width: 767px) {
                    margin-top: 0;
                    transform: none;
                    padding: 0 0 0 0;
                    width: 100%;
                    font-size: 46px;
                    max-width: 100%;
                    text-align: left;
                    margin-bottom: 20px;
                }
                .top {
                    font-size: 24px;
                    font-family: $Font_Bold;
                    color: $brand_color_2;
                    @media (max-width: 1200px) {
                        font-size: 22px;
                    }
                    @media (max-width: 991px) {
                        font-size: 20px;
                    }
                    @media (max-width: 767px) {
                        text-align: left;
                        font-size: 18px;
                    }
                }
            }
            &.descr {
                margin: 0 0 10px;
            }
        }
    }
}
.section-title {
    font-size: 44px;
    line-height: 1em;
    margin: 0px 0px 10px;
    font-style: normal;
    font-weight: 300;
    @media only screen and (max-width: 1400px) {
        font-size: 40px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 34px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 28px;
    }
    /*
	padding-left: $title_push;
	+ div,
	+ div + div{
		padding-left: $title_push;		
		@media only screen and (max-width: 767px){
			padding-left: $small_title_push;	
		}
	}
	&::before {
		border-top: 1px solid;
		content: '';
		left: 0;
		margin-top: 0.5em;
		position: absolute;
		width: calc(#{$title_push} - 20px);
	
		@media only screen and (max-width: 767px){
			width: calc(#{$small_title_push} - 20px);	
		}
	}
	*/
}
.section-top-title {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300; //padding-left: $title_push;
    @media only screen and (max-width: 767px) {
        font-size: 12px; //padding-left: $small_title_push;	
    }
}
.section-image-full {
    height: 600px;
    background-size: cover;
    background-position: center center;
}

.section-home .section-image-full{
    position:relative;
    &:after{
        position: absolute;
        bottom:0;
        left:0;
        content: "";
        width: 100%;
        height: 50px;
        background-color:#fff;
    }
     @media only screen and (max-width: 767px) {
        height: 300px;
    }
} 


.section-bottom-title {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0px 0px 2em;
    font-weight: 300; //padding-left: $title_push;
    color: #666;
    @media only screen and (max-width: 767px) {
        //padding-left: $small_title_push;
        font-size: 14px;
    }
}
.section-content {
    color: #666;
    h2, h3, h4 {
        font-size: 22px;
        font-weight: 500;
    }
}
.fleet-download {
    margin-top: 2em;
    position: relative;
    a:nth-child(2) {
        position: relative;
        &:after {
            position: absolute;
            content: "";
            left: 0;
            height: 30px;
            top: 44%;
            width: 1px;
            background: #000;
            z-index: 2;
            pointer-events: none;
            -ms-transform: translate(-50%, 0);
            transform: translate(0, -50%);
            @media only screen and (max-width: 767px) {
                display:none;
            }
        }
    }
}
.brochure-link {
    display: inline-block;
    padding: 10px 32px;
    border: none;
    color: #000;
    position: relative;
    font-weight: 400;
    text-decoration: none;
    font-weight: 500;
    font-size: 22px;
    @media only screen and (max-width: 1200px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 991px) {
        font-size: 18px;
        padding: 8px 20px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 16px;
        padding: 8px 15px;
    }
    span {
        line-height: 1;
        top: -4px;
        position: relative;
        padding-left: 10px;
        letter-spacing: 0.5px;
    }
    &:hover {
        text-decoration: none;
        color: $call_to_action;
    }
    svg {
        fill: $call_to_action;
        display: inline-block;
        width: 26px;
        height: 26px;
        @media only screen and (max-width: 767px) {
            width:22px;
            height: 22px;
        }
        .st-stroke {
            stroke: $call_to_action;
        }
    }
}
.more {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 46px;
    a {
        color: #000;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    .arrow {
        position: relative;
        font-size: 20px;
        top: 4px;
        margin-left: 5px;
        @media only screen and (max-width: 767px) {
            font-size: 18px;
            top: 2px;
        }
    }
}
.bg-cover {
    position: relative;
    background-size: cover;
    background-position: center center;
    min-height: 50vh;
    @media only screen and (max-width: 991px) {
        min-height: 400px;
    }
    @media only screen and (max-width: 767px) {
        min-height: 350px;
    }
    @media only screen and (max-width: 480px) {
        min-height: 300px;
    }
    .mask-v {
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(10%, rgba(153, 153, 153, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0) 10%, rgba(0, 0, 0, 0.8) 100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .caption {
        position: absolute;
        color: #fff;
        bottom: 30px;
        left: 40px;
        right: 40px;
        z-index: 2;
        .grid-top-title {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 300;
            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
        .grid-title {
            font-size: 30px;
            line-height: 46px;
            display: inline-block;
            @media only screen and (max-width: 991px) {
                font-size:28px;
                line-height: 44px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 24px;
                line-height: 40px;
            }
            @media only screen and (max-width: 480px) {
                font-size: 18px;
                line-height: 38px;
            }
            @media only screen and (max-width: 380px) {
                font-size: 17px;
            }
        }
        .icon-title {
            display: block;
            float: left;
            border-right: 1px solid #ffffff;
            margin-right: 10px;
            padding-right: 10px;
            line-height: 1;
            width: 65px;
            fill: #fff;
            @media only screen and (max-width: 991px) {
                width: 60px;
            }
            @media only screen and (max-width: 767px) {
                width: 55px;
            }
            @media only screen and (max-width: 480px) {
                width: 45px;
            }
            svg {
                width: 62px;
                height: 62px;
                @media only screen and (max-width: 767px) {
                    width:44px;
                    height: 44px;
                }
                @media only screen and (max-width: 480px) {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}
.relative {
    position: relative;
}
.grid-item-full {
    position: relative;
    .bg-cover {
        position: absolute;
        height: 100%;
        min-height: 50vh;
        @media only screen and (max-width: 991px) {
            position: relative;
            min-height: 400px;
        }
        @media only screen and (max-width: 767px) {
            min-height: 350px;
        }
        @media only screen and (max-width: 480px) {
            min-height: 300px;
        }
    }
    .text {
        min-height: 50vh;
        @media only screen and (max-width: 991px) {
            position: relative;
            min-height: 400px;
        }
        @media only screen and (max-width: 767px) {
            min-height: auto;
        }
        @media only screen and (max-width: 480px) {
            min-height: auto;
        }
        .section {
            padding: 110px 40px;
            @media only screen and (max-width: 1500px) {
                padding: 80px 40px;
            }
            @media only screen and (max-width: 991px) {
                padding: 80px 0;
            }
            @media only screen and (max-width: 767px) {
                padding: 50px 0;
            }
            .content {
                position: relative;
                .more {
                    float: none;
                    padding-left: $title_push;
                    @media only screen and (max-width: 767px) {
                        padding-left: $small_title_push;
                    }
                    &.external {
                        padding: 10px 0 0;
                        @media only screen and (max-width: 767px) {
                            margin-left: -30px;
                        }
                        a {
                            color: #fff;
                            padding: 10px 20px;
                            background: #000;
                            font-size: 16px;
                            display: table;
                            @media only screen and (max-width: 767px) {
                                font-size: 12px;
                                margin: 0 auto;
                                line-height: 30px;
                            }
                            &:hover {
                                background: #01363f;
                            }
                        }
                    }
                }
            }
        }
    }
}
.list-item-full {
    padding: 20px 0;
    position: relative;
    .bg-cover {
        position: absolute;
        height: 100%;
        min-height: 50vh;
        background-position: center calc(50% + 15px)!important;
        background-repeat: no-repeat;
        @media only screen and (max-width: 991px) {
            position: relative;
            min-height: 400px;
        }
        @media only screen and (max-width: 767px) {
            min-height: 350px;
        }
        @media only screen and (max-width: 480px) {
            min-height: 300px;
        }
    }
    .text {
        min-height: 50vh;
        @media only screen and (max-width: 991px) {
            position: relative;
            min-height: 400px;
        }
        @media only screen and (max-width: 767px) {
            min-height: auto;
        }
        @media only screen and (max-width: 480px) {
            min-height: auto;
        }
        .section {
            padding: 110px 40px;
            @media only screen and (max-width: 1500px) {
                padding: 80px 40px;
            }
            @media only screen and (max-width: 991px) {
                padding: 50px 0;
            }
            @media only screen and (max-width: 767px) {
                padding: 30px 0;
            }
            .content {
                position: relative;
                .section-title {
                    font-size: 20px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    margin: 0px 0px 10px;
                    font-weight: 300;
                    color: #666;
                    @media only screen and (max-width: 767px) {
                        font-size: 14px;
                    }
                }
                .section-top-title {
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: 300;
                    @media only screen and (max-width: 767px) {
                        font-size: 12px;
                    }
                }
                .section-image-full {
                    height: 600px;
                    background-size: cover;
                    background-position: center center;
                }
                .section-bottom-title {
                    text-transform: initial;
                    font-size: 44px;
                    line-height: 1em;
                    margin: 0px 0px 2em;
                    font-style: normal;
                    font-weight: 300;
                    @media only screen and (max-width: 1400px) {
                        font-size: 40px;
                    }
                    @media only screen and (max-width: 767px) {
                        font-size: 34px;
                        margin: 0px 0px 1em;
                    }
                    @media only screen and (max-width: 480px) {
                        font-size: 28px;
                    }
                }
                .more {
                    float: none;
                    padding-left: $title_push;
                    @media only screen and (max-width: 767px) {}
                    &.external {
                        padding: 10px 0 0;
                        @media only screen and (max-width: 767px) {
                            margin-left: -30px;
                        }
                        a {
                            color: #fff;
                            padding: 10px 20px;
                            background: #000;
                            font-size: 16px;
                            display: table;
                            @media only screen and (max-width: 767px) {
                                font-size: 12px;
                                margin: 0 auto;
                                line-height: 30px;
                            }
                            &:hover {
                                background: #01363f;
                            }
                        }
                    }
                }
            }
        }
    }
}
.section-independent {
    color: #fff;
    text-align: center;
    min-height: auto;
    .container {
        position: relative;
        z-index: 1;
    }
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        background-color: rgba(0, 0, 0, .4);
    }
    h2 {
        margin: 0 0;
        font-family: $Font_ExtraBold;
        font-size: 39px;
        text-shadow: 0 0px 5px rgba(0, 0, 0, 0.6);
        line-height: 1.3;
        @media only screen and (max-width: 1200px) {
            font-size: 36px;
            line-height: 1.2;
        }
        @media only screen and (max-width: 991px) {
            font-size: 32px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 1.1;
        }
    }
}
@media (max-height: 900px) {
    #about_section {
        .count-on-table {
            padding: 10px 0 45px;
        }
        .count-on-table-container {
            margin-top: 45px;
            margin-bottom: 40px;
        }
    }
}
@media (max-height: 800px) {
    #about_section {
        .count-on-table {
            padding: 5px 0 45px;
            .col p.descr {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .count-on-table-container {
            margin-top: 40px;
            margin-bottom: 35px;
        }
    }
}
.partners-logos {
    .grid_section_title {
        padding-top: 10px;
        .grid-title {
            text-align: center;
            font-size: 28px;
            color: #fff;
            @media (max-width: 991px) {
                font-size: 26px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
            @media (max-width: 480px) {
                font-size: 22px;
            }
        }
    }
    .bg-blue {
        background-color: #001a33;
    }
    .partners-list {
        display: table;
        margin: 0px auto 10px auto;
        ul {
            li {
                float: left;
                width: 100%;
                .partner-logo {
                    display: table;
                    margin: 0 auto;
                    padding: 40px 30px;
                    &.booking {
                        padding: 0px 30px;
                        img {
                            @media (max-width: 767px) {
                                max-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}
header {
    .banner {
        background-color: #005ca9;
        a, img {
            display: table;
            margin: 0 auto;
        }
        img {
            max-height: 100px;
        }
    }
    &.sticky {
        .banner {
            display: none;
        }
    }
}
.place-order {
    font-size: 22px;
    font-weight: 400;
    text-decoration: none;
    margin-top: 35px;
    display: table;
    @media (max-width: 991px) {
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 767px) {
        font-size: 18px;
    }
    &:hover {
        color: $call_to_action;
        text-decoration: none;
    }
    .cart-icon {
        display: inline-block;
        vertical-align: middle;
        padding-right: 5px;
        svg {
            width: 32px;
            height: 32px;
            fill: $call_to_action;
            @media only screen and (max-width: 767px) {
                width: 22px;
                height: 22px;
            }
        }
    }
}
.cta-link {
    background-color: $call_to_action;
    color: $brand_color;
    min-width: 250px;
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    transition: all ease-in-out 0.3s;
    text-align: center;
    margin: 0 auto;
    box-shadow: none;
    border: none;
    &.cta-blue{
         background-color: $brand_color_2;
         color: #fff;
    }
    &:hover {
        background-color: $brand_color;
        color: #fff;
        text-decoration: none;
    }
}
.fleet_nav {
    .fleet_nav-list {
        display: block;
        text-align: center;
        li {
            padding: 15px;
        }
    }
}
.cta-about {
    text-align: center;
}
.white-text {
    color: #fff;
}
.fleet_nav-grid {
    .fleet-nav-item {
        .pic {
            overflow: hidden;
            position: relative;
        }
        h4 {
            text-align: center;
            margin-bottom: 20px;
        }
        img {
            transition: all 1s;
        }
        .full-box-link {
            z-index: 1;
        }
        &:hover img {
            transform: scale(1.5);
            opacity: .8;
        }
    }
}
.dufour-yachts {
    padding-bottom: 90px;
    .dufour-bg-cover {
        position: relative;
        background-size: 100% auto;
        background-position: center center;
        min-height: 160px;
        background-repeat: no-repeat;
    }
    .inner-content{
        padding:80px 0; 
         @media only screen and (max-width: 767px) {
             padding:50px 0; 
         }
    }
    .dufour-yacht {
        display: block;
        text-decoration: none;
        .d-yacht-name {
            font-size: 22px;
            font-weight: 500;
            padding: 10px 0 0;
        }
    }
    .dufour-title {
        padding: 50px 0 200px;
        font-size: 42px;
        background-color:#00507F;
        color: #fff;
        position:relative;
        @media only screen and (max-width: 767px) {
            padding: 40px 0 150px;
            font-size: 32px;
        }
        .dufour-logo {
            display: inline-block;
            position: relative;
            padding: 10px 30px;
            vertical-align: middle;
            margin-right: 30px;
            line-height: 1;
            
            @media only screen and (max-width: 767px) {
                display: block;
                 margin-right: 0px;
            }
            &:after {
                position: absolute;
                content: "";
                height: 60px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                background-color: #fff;
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
            .dufour_logo {
                fill: #fff;
                width: 250px;
                height: 50px;
                @media only screen and (max-width: 767px) {
                    width: 220px;
                    height: 45px;
                }
            }
        }
    }
    .thr-divider {
        background-color:rgba(255,255,255,.8);
        display: block;
        padding: 15px;
        position:absolute;
        bottom: 40px;
        width: 100%;
     	.thr-divider-wrapper{
	        font-family: $Font_ExtraBold;
	        font-size: 36px;
	        line-height: 1;
	        margin-top: 0;
	        font-style: normal;
	        margin: 0 auto;
	        display: table;
	        padding: 0;
	        width: auto;
	        color: #000;
	        @media only screen and (max-width: 1500px) {
	            font-size: 34px;
	        }
	        @media only screen and (max-width: 1200px) {
	            font-size: 32px;
	        }
	        @media only screen and (max-width: 991px) {
	            font-size: 28px;
	        }
	        @media only screen and (max-width: 767px) {
	            font-size: 24px;
	        }
	        @media only screen and (max-width: 480px) {
	            font-size: 20px;
	        }
	         svg {
            	height: 80px;
	            width: 80px;
	            fill: #000;
	            display: inline-block;
	            vertical-align: middle;
	            @media only screen and (max-width: 991px) {
	                height:60px;
	                width: 60px;
	            }
	            @media only screen and (max-width: 767px) {
	                height: 50px;
	                width: 50px;
	            }
	        }
	        span {
	            display: inline-block;
	            vertical-align: middle;
	            margin-left: 20px;
	            padding: 23px 0 20px 25px;
	            letter-spacing: 0.7px;
	            border-left: 2px solid $call_to_action;
	            @media only screen and (max-width: 767px) {
	                padding: 23px 0 20px 10px;
	                text-align: left;
	                margin-left: 10px;
                    font-size: 18px;
	            }
                 @media only screen and (max-width: 480px) {
                    max-width: 230px;
                 }
	        }
     	}
       
    }

}