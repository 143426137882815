body.section-availability {
    .section-availability {
        .section-content {
            h5 {
                background: none;
            }
        }
    }
}
.section-availability {
    padding: 0;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-bottom: 50px;
    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 50px;
        background-color: #fff;
    }
    .section-content {
        min-height: 100vh;
        h5 {
            font-size: 30px;
            font-weight: 500;
            margin: 0;
            padding: 90px 15px 40px;
            margin-bottom: 80px;
            text-align: center;
            color: #000;
            background: #fff;
            margin: 0;
            @media only screen and (max-width: 991px) {
                padding: 90px 15px 40px;
                font-size: 26px;
            }
            @media only screen and (max-width: 767px) {
                padding: 30px 15px 30px;
                font-size: 22px;
            }
            svg {
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
        }
        .availability-description {
            color: #000;
            padding: 70px 0;
            max-width: 550px;
            margin: 0 auto;
            display: block;
        }
        .availability-form {
            background: rgba(255, 255, 255, 0.5);
            padding: 50px 15px;
            form[name="availability_form"] {
                display: table;
                margin: 0 auto;
                text-align: center;
                .col {
                    min-width: 380px;
                    display: inline-block;
                    text-align: center;
                    padding: 0 70px;
                    @media only screen and (max-width: 991px) {
                        padding:0 50px;
                    }
                    @media only screen and (max-width: 767px) {
                        min-width: auto;
                        max-width: 380px;
                        width: 100%;
                        padding: 0 30px;
                    }
                    @media only screen and (max-width: 480px) {
                        padding: 0 15px;
                    }
                    svg {
                        height: 27px;
                        width: auto;
                        fill: $call_to_action;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 7px;
                        @media only screen and (max-width: 991px) {
                            height:22px;
                        }
                    }
                    label {
                        display: block;
                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                    * {
                        outline: 0;
                    }
                    input {
                        text-align: center;
                        border-radius: 0px;
                        font-size: 16px;
                        &:focus {
                            border-color: $brand_color;
                            outline: 0;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                    }
                    .btn {
                        border-color: $call_to_action;
                        background: $call_to_action;
                        display: block;
                        width: 100%;
                        border-radius: 0;
                        outline: none!important;
                        &:hover {
                            border-color: $brand_color;
                            background: $brand_color;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.select2-container--default {
    .select2-selection--single {
        border: 1px solid #ccc;
        border-radius: 0px;
        height: 45px;
        @media only screen and (max-width: 767px) {
         	height: 35px;
         }
        .select2-selection__rendered {
            line-height: 45px;
             @media only screen and (max-width: 767px) {
             	line-height: 35px;
             }
        }
        .select2-selection__arrow b {
            border: none;
            &:after {
                position: absolute;
                content: "\f107";
                font: normal normal normal 14px/1 FontAwesome;
                left: -12px;
                font-size: 32px;
                top: -6px;
                line-height: 1;
                @media only screen and (max-width: 767px) {
		         	 top: -10px;
		         }
            }
        }
    }
}
.daterangepicker td.in-range {
    background-color: rgba(0, 102, 179, 0.2);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $call_to_action;
    color: #000;
}
.daterangepicker {
    .drp-buttons {
        .drp-notice {
            display: block;
            text-align: right;
            font-weight: bold;
            padding: 0 0 10px;
        }
    }
    .table-condensed th {
        color: #000;
    }
    .calendar-table th.month {
        color: #000;
    }
    .calendar-table td {
        border-radius: 0px!important;
        margin: 5px;
    }
    td.active, td.active:hover {
        background-color: $call_to_action;
        border-color: transparent;
        color: #fff;
    }
    td.start-date, td.end-date, td.start-date.end-date {
        border-radius: 0px;
    }
    .btn {
        border-radius: 0;
    }
    .btn-primary {
        color: #fff;
        background-color: $call_to_action;
        border-color: $call_to_action;
    }
    .table-condensed {
        tbody {
            td {
                pointer-events: none;
            }
            td:last-of-type {
                background-color: $call_to_action;
                pointer-events: all;
                &.active, &:hover {
                    background-color: $brand_color;
                    border-color: transparent;
                    color: #fff;
                }
            }
        }
    }
}
.loading-availability {
    display: table;
    margin: 50px auto;
    color: #001a33;
    font-size: 60px;
}
.section-availability-list {
    padding: 0;
    .section-title {
        background: #a6b2cc;
        h2 {
            font-size: 30px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 30px;
            text-align: center;
            color: #fff;
            padding: 80px 15px 80px;
            @media only screen and (max-width: 991px) {
                padding: 50px 15px 50px;
                font-size: 26px;
                margin-bottom: 15px;
            }
            @media only screen and (max-width: 767px) {
                padding: 30px 15px 30px;
                font-size: 22px;
                margin-bottom: 0px;
            }
            svg {
                height: 25px;
                width: 25px;
                margin-right: 5px;
            }
        }
    }
    del {
        color: maroon;
    }
    .section-content {
        color: #000;
        padding: 70px 0 120px;
        @media only screen and (max-width: 991px) {
            padding: 40px 0 80px;
        }
        @media only screen and (max-width: 767px) {
            padding: 20px 0 50px;
        }
        h5 {
            font-size: 30px;
            font-weight: 500;
            margin: 0px;
            padding: 30px 15px;
            text-align: center;
            color: #000;
            background: #fff;
            .available-dates {
                display: inline-block;
                @media only screen and (max-width: 767px) {
                    display: block;
                    margin-top: 5px;
                }
            }
            @media only screen and (max-width: 991px) {
                font-size: 24px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 20px;
            }
        }
        .block-center {
            display: table;
            margin: 30px auto;
            @media only screen and (max-width: 767px) {
                float: left;
            }
            @media only screen and (max-width: 480px) {
                display: table;
                margin: 0 auto;
                float: none;
            }
        }
        .block-right {
            float: right;
            @media only screen and (max-width: 480px) {
                display: table;
                margin: 0 auto;
                float: none;
            }
        }
        .availability-list {
            .cta-link.slide-to {
                margin-bottom: 30px;
            }
            .availability-item {
                border-top: 2px solid rgba(0, 0, 0, .15);
                padding: 50px 0;
                .col-xxs-12 {
                    @media only screen and (max-width: 480px) {
                        width: 100%;
                    }
                }
                img {
                    @media only screen and (max-width: 767px) {
                        margin-bottom: 15px;
                    }
                }
                .bg-overlay {
                    overflow: hidden;
                    .bg-cover {
                        min-height: 250px;
                        transition: all 1s;
                    }
                }
                &:hover {
                    .bg-cover {
                        -ms-transform: scale(1.5);
                        transform: scale(1.5);
                        opacity: .8;
                    }
                }
                .title {
                    font-size: 27px;
                    line-height: 1;
                    @media only screen and (max-width: 991px) {
                        font-size: 24px;
                    }
                    span {
                        display: inline-block;
                        color: $call_to_action;
                    }
                }
                .cta-label {
                    background: #dbdcdd;
                    display: block;
                    color: #001a33;
                    width: 100%;
                    padding: 10px 25px;
                    text-decoration: none;
                    display: block;
                    text-align: center;
                    &.yellow {
                        background-color: #4A96D1;
                    }
                }
                .title, .cta-label, .cta-link, .dates, .availability, .location, .beds {
                    margin-bottom: 15px;
                    font-weight: 500;
                }
                .title, .location, .dates, .availability, .beds {
                    padding: 10px;
                }
                .svg-icon {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 7px;
                    line-height: 1;
                    svg {
                        width: 22px;
                        height: 22px;
                        fill: $call_to_action;
                        @media only screen and (max-width: 991px) {
                            width:19px;
                            height: 19px;
                        }
                    }
                }
                .cta-link {
                    display: block;
                    &.price {
                        background: #dbdcdd;
                    }
                    @media only screen and (max-width: 991px) {
                        min-width: 200px;
                    }
                }
                .block-right {
                    margin: 30px auto;
                    @media only screen and (max-width: 480px) {
                        margin: 0px auto;
                    }
                }
                .dates {
                    position: relative;
                    i {
                        position: absolute;
                        top: 50%;
                        color: #fff;
                        transform: translateY(-50%);
                        font-size: 36px;
                        line-height: 1;
                        &.fa-angle-left {
                            left: 20px;
                        }
                        &.fa-angle-right {
                            right: 20px;
                        }
                    }
                    &.yellow {
                        background-color: #4A96D1;
                        color: #001a33;
                    }
                }
                .cnct {
                    display: block;
                    padding: 10px 25px;
                    text-align: center;
                    text-decoration: none;
                    transition: all ease-in-out .3s;
                    &:hover {
                        color: $call_to_action;
                    }
                }
            }
        }
    }
}
.availability-cta {
    svg {
        width: 25px;
        height: 13px;
        stroke: $call_to_action;
        line-height: 1;
        z-index: 1;
        @media only screen and (max-width: 767px) {
            position: relative;
            right: auto;
            top: 5px;
            margin-right: 5px;
        }
    }
}