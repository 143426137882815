.testimonials{
	padding: 50px 0 135px ;
	font-size: 16px;
	@media only screen and (max-width: 991px){
		font-size: 18px;
	}
	@media only screen and (max-width: 767px){
		font-size: 18px;
	}
	.container{
		padding: 0 31px;
		@media only screen and (max-width: 1699px){
			padding: 0 15px;
		}
	}
	.section-title+div{
		@media only screen and (max-width: 991px){
			padding-left: 0px;
		}
	}
	.testimonials-list{
		
		.item{
			padding: 25px 31px 33px 0;
		
			@media only screen and (max-width: 1699px){
				padding: 25px 15px 33px 0;
			}
			.item-content{
				position: relative;
				&:before{
					position: absolute;
					content: "";
					left: 25px;
					bottom: 25px;
					top: -25px;
					right: 0;
					border: 1px solid #63666a;
					@media only screen and (max-width: 767px){
						left: 10px;
						bottom: 10px;
						top: -10px;
						right: 0;
					}
				}
				.icon{
					color: $brand_color_2;
					font-size: 80px;
					position: relative;
					line-height: 100%;	
					float: left;
					
					@media only screen and (max-width: 767px){
						font-size: 50px;
						line-height: 50px;
						top: 11px;
						left: 17px;
						float: left;
    				    width: 38px;
					}					   
				}
				.quote-box{
					margin: 25px 50px 0 0;
					padding: 25px 45px 60px 45px ;
					background: #f2f3f6;
					color: #000;
					
					font-family: $Font_Light;
					font-weight:300;
					text-align: left;
					@media only screen and (max-width: 1699px){
						margin: 25px 25px 0 0;
						padding: 20px 30px 40px 30px;
					}
					@media only screen and (max-width: 1200px){
						margin: 20px 20px 0 0;
						padding: 15px 30px 50px 30px;
						font-size: 18px;
					}
					@media only screen and (max-width: 767px){
						margin: 15px 15px 0 0;
						padding: 10px 10px 50px 10px;
						font-size: 16px;
					}
					.quote{
						padding: 22px 0 0 40px;
						width: calc(100% - 38px);
						p{
							font-size: 16px;
						}
					}
				}								
			}
		}
	}
	.arrow{
		position: absolute;
		top: 50%;
		z-index: 50;
		-ms-transform: translateY(-50%);
   			transform: translateY(-50%);
		svg{
			width: 31px;
			height: 60px;
			@media only screen and (max-width: 767px){
				width: 16px;
				height: 34px;	
			}
			   
		}
	}
	.arrow-left{
		left: -31px;
		svg{
			-ms-transform: rotate(-180deg);
   				transform: rotate(-180deg);
		}
		@media only screen and (max-width: 767px){
			left: -10px;	
		}	
	}
	.arrow-right{
		right: -31px;
		@media only screen and (max-width: 767px){
			right: -10px;	
		}
	}
}