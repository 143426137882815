.login-modal, .form-wrapper {
    background-color: #f6f6f6;
    display: block;
    margin: 0 auto;
    margin-top: 90px;
    width: 100%;
    max-width: 830px;
    padding: 50px 150px;
    border-radius: 30px;
    color: #000;

    @media (max-width: 991px) {
        padding: 50px 100px;
    }
    @media (max-width: 767px) {
        padding: 50px;
    }

    @media (max-width: 480px) {
        padding: 50px 15px;
    }

    .modal-body{
        padding:0;
    }

    .section-header {
        h2 {
            font-size: 32px;
            
            @media (max-width: 767px) {
                font-size: 28px;
            }

            @media (max-width: 480px) {
                font-size: 24px;
            }

            .fa {
                color: $call_to_action;
            }
        }
    }

    .cta-link{
        width: 100%;
         max-width: 250px;
         min-width: auto;
          @media (max-width: 767px) {
           max-width: 180px;
        }
          @media (max-width: 480px) {
           float: none!important;
           display: block;
           max-width:250px;
           margin-bottom: 10px;
        }
    }
    
    .btn-submit {
        margin-top: 50px;
         @media (max-width: 767px) {
             margin-top: 30px;
        }
    }
    .register-code {
        padding-top: 80px;
         @media (max-width: 767px) {
             padding-top: 30px;
        }
    }
}
.modal-content.login-modal {
    padding: 30px;
    margin-top: 0px;
    position: relative;
    .close {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 1;
        svg {
            width: 30px;
            height: 30px;
        }
    }
}
.subscription-details {
    .subscription-details-section {
        padding: 90px 0;
         @media (max-width: 991px) {
             padding: 50px 0;
         }
        .subscription__wrapper {
            padding-bottom: 50px;
            h2 {
                font-size: 38px;
                margin-top: 0;
                font-weight: 200;
                margin-bottom: 35px;
                @media (max-width: 991px) {
                    font-size: 32px;
                }
                @media (max-width: 767px) {
                    font-size: 24px;
                }
                .benefit-icon{
                	display: inline-block;
                	line-height: 1;
                	vertical-align: middle;
            	    margin-right: 20px;
                	svg{
                		fill: $call_to_action;
                		width: 40px;
                		height: 40px;
                	}
                }
            }
            .description{
            	padding-bottom:50px;
            }
        }
        .card {
            display: table;
            position:relative;
            max-width: 70%;
             @media (max-width: 991px) {
                margin: 0 auto 30px;
            }
             @media (max-width: 480px) {
                 max-width: 100%;
             }
            

            img{
                @media (max-width: 480px) {
                    max-height: 180px;
                }
                  
            }


            .card-info{
                position:absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 30px;
                 @media (max-width: 767px) {
                    padding: 15px;
                }


               
            }

            .subs-icon{
                position:absolute;
                top: 0;
                right: 0;
                padding: 30px;
                display: block;
                 @media (max-width: 767px) {
                    padding: 15px;
                }
                 span {
                    display: block;
                    line-height: 1;
                   
                    svg {
                        fill: #fff;
                        height: 60px;
                        width: 60px;
                         @media (max-width: 480px) {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
            .membership-expriration,
            .membership-number,
            .membership-title{
            	font-size: 18px;
            	letter-spacing: 2px;
                color: #fff;
                line-height: 1.2;
                 @media (max-width: 480px) {
                      font-size: 14px;
                }
            }
           
           
            .card-label{
            	display: block;
            	letter-spacing: 1px;
            	font-size: 12px;
                font-weight: 600;
                 @media (max-width: 480px) {
                     font-size: 10px;
                }
            }
            .subscription-name {
                color: $brand_color;
                font-family: $Font_Light;
                text-align: center;
                font-size: 32px;
                font-weight: 200;
                 padding-bottom: 10px;
                 @media (max-width: 480px) {
                     font-size: 22px;
                      padding-bottom: 3px;
                }
               
            }
        }
        .cta-link{
            margin-bottom: 10px;
        }
    }
}