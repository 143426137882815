#hero_section {
    padding: 0;
    min-height:  calc(100vh + 30px);
   
    @media only screen and (max-width: 1200px) {
        min-height: 500px;
    }
    @media only screen and (max-width: 767px) {
        min-height: 350px;
    }
    .background{
        top: -30px;
        @media only screen and (max-width: 767px) {
             top: 0px;
        }
    }
    /*&.short{
        padding: 0;         
        .container{
            vertical-align: bottom;
            height: 100vh;
            padding:0;
            
            .hero-content{
                padding-top: calc(100vh - 140px);
                
                @media only screen and (max-width: 767px){
                    padding-top: calc(100vh - 120px);
                }
                @media only screen and (max-width: 480px){
                    padding-top: calc(100vh - 100px);
                }
                
                .title_section{
                    font-family: $Font_ExtraBold;
                    font-size: 24px;
                    font-weight: 300;
                    text-transform: uppercase;
                    padding: 20px 40px;
                    color: #fff;
                    background: rgba(0,0,0,.6);
                    
                    @media only screen and (max-width: 991px){
                        font-size: 22px;
                    }
                    @media only screen and (max-width: 767px){
                        font-size: 20px;
                        padding: 20px;
                    }
                    @media only screen and (max-width: 480px){
                        padding: 15px;
                        font-size: 18px;
                    }
                    
                    .icon-title{
                        display: block;
                        float: left;
                        border-right: 1px solid #fff;
                        margin-right: 15px;
                        padding-right: 10px;
                        line-height: 1;
                        width: 73px;
                        @media only screen and (max-width: 767px){
                            margin-right: 10px;
                            width: 55px;
                        }
                        @media only screen and (max-width: 480px){
                            width: 45px;
                        }
                        @media only screen and (max-width: 380px){
                            display:none;           
                        }
                        
                        svg{
                            width:62px;
                            height: 62px;
                            fill: #fff;
                            @media only screen and (max-width: 767px){
                                width:44px;
                                height: 44px;
                            }
                            @media only screen and (max-width: 480px){
                                width:40px;
                                height: 40px;
                            }
                        }
                    }
                    .grid-top-title{
                        font-size: 14px;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        font-weight: 300;
                        @media only screen and (max-width: 767px){
                            font-size: 12px;
                        }
                    }
                    .grid-title{
                        font-size: 44px;
                        font-style: normal;
                        font-weight: 300;
                        @media only screen and (max-width: 991px){
                            font-size: 34px;
                            line-height: 65px;
                        }
                        @media only screen and (max-width: 767px){
                            font-size: 24px;
                            line-height: 46px;
                        }
                        @media only screen and (max-width: 480px){
                            font-size: 18px;
                            line-height: 42px;
                        }
                        @media only screen and (max-width: 380px){
                            font-size: 17px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }*/
    &.hero-fleet-types {
        min-height:  100vh;
        @media only screen and (max-width: 1200px) {
            min-height: 500px;
        }
        @media only screen and (max-width: 767px) {
            min-height: 350px;
        }
        .fleet_types_item {
            width: 50%;
            float: left;
            position: relative;
            min-height: 100vh;
            @media only screen and (max-width: 767px) {
                width:100%;
                min-height: 350px;
            }
            @media only screen and (max-height: 700px) and (min-width: 1201px) {
                min-height: 600px;
            }
            @media only screen and (max-height: 700px) and (max-width: 1200px) {
                min-height: 500px;
            }
            @media only screen and (max-height: 700px) and (max-width: 767px) {
                min-height: 360px;
            }
            &:hover {
                .type-title {
                    background: $call_to_action;
                }
            }
            .full-box-link {
                z-index: 1;
            }
            &:nth-child(1) {
                @media only screen and (max-width: 767px) {
                    z-index: 1;
                }
            }
        }
        .background {
            width: 100%;
            right: auto;
            display: block;
            height: 100%;
            top: auto;
            background-position: center calc(50% + 30px);
            @media only screen and (max-width: 767px) {
                width:100%;
                height: 370px;
                background-position: center calc(50% + 10px);
            }
        }
        .hero-label {
            position: absolute;
            left: 50%;
            top: 75%;
            border: 2px solid #fff;
            z-index: 10;
            color: #fff;
            transform: translate(-50%, -75%);
            font-weight: 500;
            width: 100%;
            max-width: 250px;
            text-align: center;
            transition: all ease-in-out 0.3s;
            @media only screen and (max-width: 767px) {
                top: 50%;
                transform: translate(-50%, -50%);
            }
            a {
                padding: 10px;
                color: #fff;
                display: block;
                text-decoration: none;
                transition: all ease-in-out 0.3s;
            }
            &:hover {
                background-color: $call_to_action;
                border: 2px solid $call_to_action;
                color: #000;
                a {
                    color: #000;
                }
            }
            @media only screen and (max-width: 767px) {
                max-width: 170px;
                letter-spacing: 0px;
            }
        }
        div.h4 {
            color: #fff;
            font-family: $Font_ExtraBold;
            opacity: 1;
            z-index: 2;
            position: absolute;
            left: 0%;
            top: 28%;
            transform: translate(0, -28%);
            padding: 0 30px;
            width: 100%;
            font-weight: 500;
            font-size: 38px;
            text-align: center;
            letter-spacing: 2.8px;
            transition: opacity ease-in-out 0.3s;
            @media only screen and (max-width: 1200px) {
                font-size: 32px;
            }
            @media only screen and (max-width: 991px) {
                font-size: 22px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 18px;
                opacity: 1;
            }
            .video-link {
                margin-top: 20px;
                position: relative;
                left: auto;
                right: auto;
                bottom: auto;
                display: inline-block;
                z-index: 10;
            }
        }
        span.overlay {
            opacity: 0;
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            z-index: 0;
            transition: opacity ease-in-out 0.3s;
            @media only screen and (max-width: 767px) {
                top: 0px;
            }
        }
        .fleet_types_item:hover {
            span.overlay, div.h4 {
                opacity: 1;
            }
        }
    }
    .container, .top-menu {
        z-index: 2;
    }
    .container {
        vertical-align: bottom;
    }
    .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: #000;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            @media only screen and (orientation: portrait) {
                transform: translate(-50%, -50%);
                left: 50%;
            }
        }
    }
    .video-overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $video_overlay;
    }
    .bg-container {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        >div {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            -webkit-transition: all 2s ease-out;
            -moz-transition: all 2s ease-out;
            -ms-transition: all 2s ease-out;
            -o-transition: all 2s ease-out;
            transition: all 2s ease-out;
            opacity: 0;
            &.active {
                opacity: 1;
                -webkit-animation: intro_slideshow 8s infinite;
                -o-animation: intro_slideshow 8s infinite;
                animation: intro_slideshow 8s infinite;
                &.slower {
                    -webkit-animation-duration: 12s;
                    -o-animation-duration: 12s;
                    animation-duration: 12s;
                }
            }
        }
    }
    .overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $image_overlay;
        pointer-events: none;
    }
    .hero-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        top: auto;
        background: rgba(255, 255, 255, 0.8);
        text-align: center;
        padding: 22px 0;
        @media only screen and (max-height: 700px) {
            bottom: 40px;
            padding: 12px;
        }
        @media only screen and (max-height: 400px) {
            bottom: 40px;
        }
        @media only screen and (max-width:767px) {
            padding: 10px 0;
        }
        h1 {
            font-style: italic;
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
            color: #fff;
            padding-bottom: 6px;
            width: 80%;
            transition: all 0.5s ease-out;
            transform: translate3d(0, 30px, 0);
            opacity: 0;
            @media only screen and (max-width: 991px) {
                font-size: 18px;
                line-height: 24px;
                width: 100%;
            }
            &.visible {
                -webkit-transform: translate3d(0, 0, 0);
                -moz-transform: translate3d(0, 0, 0);
                -ms-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
            &.main-title {
                font-family: $Font_ExtraBold;
                font-size: 36px;
                line-height: 1;
                margin-top: 0;
                font-style: normal;
                text-align: center;
                padding: 0;
                width: auto;
                margin: 0;
                color: #000;
                @media only screen and (max-width: 1500px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 1200px) {
                    font-size: 32px;
                }
                @media only screen and (max-width: 991px) {
                    font-size: 28px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 24px;
                }
                @media only screen and (max-width: 480px) {
                    font-size: 20px;
                }
                /*
                &::before {
                    border-top: 1px solid;
                    content: '';
                    left: 0;
                    margin-top: 0.5em;
                    position: absolute;
                    width: calc(#{$title_push} - 20px);
        
                    @media only screen and (max-width: 767px){
                        width: calc(#{$small_title_push} - 20px);   
                    }
                }
                */
            }
            svg {
                height: 80px;
                width: 80px;
                fill: #000;
                display: inline-block;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    height:60px;
                    width: 60px;
                }
                @media only screen and (max-width: 767px) {
                    height: 50px;
                    width: 50px;
                }
            }
            span {
                display: inline-block;
                vertical-align: middle;
                margin-left: 20px;
                padding: 23px 0 20px 25px;
                letter-spacing: 0.7px;
                border-left: 2px solid $call_to_action;
                @media only screen and (max-width: 767px) {
                    padding: 23px 0 20px 10px;
                    text-align: left;
                    margin-left: 10px;
                    font-size: 18px;
                }
                @media only screen and (max-width: 480px) {
                    max-width: 230px;
                }
            }
        }
    }
    .capitalized-top-title {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 300;
        padding-left: $title_push;
        color: #fff;
        @media only screen and (max-width: 767px) {
            padding-left: $small_title_push;
        }
    }
    .description {
        font-style: normal;
        font-size: 20px;
        font-weight: 300;
        line-height: 18px;
        color: #fff;
        padding-left: $title_push;
        -webkit-transition: all 0.8s ease-out;
        -moz-transition: all 0.8s ease-out;
        -ms-transition: all 0.8s ease-out;
        -o-transition: all 0.8s ease-out;
        transition: all 0.8s ease-out;
        position: relative;
        @media only screen and (max-width: 1200px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 16px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            padding-left: $small_title_push;
        }
        &.visible {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
    .arrow-container {
        position: absolute;
        bottom: 20px;
        width: auto;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        .scroll-down img {
            -webkit-animation: bounce 2s infinite;
            -o-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            backface-visibility: hidden;
            width: 44px;
            @media only screen and (max-width: 991px) {
                width: 40px;
            }
            @media only screen and (max-width: 767px) {
                width: 30px;
            }
        }
    }
}
.home-hero {
   min-height:  100vh!important;
   
    @media only screen and (max-width: 1200px) {
        min-height: 500px!important;
    }
    @media only screen and (max-width: 767px) {
        min-height: 350px!important;
    }
    .hero-content {
        padding: 0!important;
        padding-top: 65vh!important;
        position: relative!important;
        bottom: auto!important;
        background: none!important;
        text-align: left!important;
        h1.main-title {
            margin: 0!important;
            font-size: 64px!important;
            line-height: 1em!important;
            margin-top: 0!important;
            padding-left: 55px!important;
            font-style: normal!important;
            margin-bottom: .1em!important;
            color: #fff!important;
            padding-bottom: 6px!important;
            width: 80%!important;
            font-weight: 300!important;
            display: block!important;
            text-align: left!important;
            @media only screen and (max-width: 1500px) {
                font-size: 42px!important;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 38px!important;
            }
            @media only screen and (max-width: 991px) {
                font-size: 36px!important;
            }
            @media only screen and (max-width: 767px) {
                font-size: 28px!important;
                padding-left: 20px!important;
            }
            @media only screen and (max-width: 480px) {
                font-size: 24px!important;
            }
        }
    }
}
header {
    background: transparent!important;
    border-bottom: none!important;
    @media only screen and (max-width: 767px) {
        background: #fff!important;
        border-bottom: 1px solid #f2f3f6;
    }
    .top-menu .burger .line {
        background: #fff!important;
        @media only screen and (max-width: 767px) {
            background:#000!important;
        }
    }
    .top-menu .pages .menu-item .page_link {
        color: #fff!important;
        @media only screen and (max-width: 767px) {
            color:#000!important;
        }
    }
    .top-menu .pages .menu-item .page_link:hover .fa {
        @media only screen and (max-width: 767px) {
            color:#fff!important;
        }
    }
    .stText {
        fill: #fff!important;
        @media only screen and (max-width: 767px) {
            fill:#000!important;
        }
    }
}
.no-hero header, .show-responsive header, header.sticky {
    background: #fff!important;
    border-bottom: 1px solid #f2f3f6;
    .top-menu .pages .menu-item .page_link {
        color: #000!important;
    }
    .top-menu .burger .line {
        background: #000!important;
    }
    .stText {
        fill: #000!important;
    }
}


.members-nav{
    .dropdown-menu{
            top: calc(100% + 7px);
    }
}
.members-btn{
    background-color:$brand_color_2;
    color: #fff!important;
    padding:10px 15px!important;
    transition: all 0.3s;
    &:hover{
        background-color:$brand_color;
    }
    &:before{
        display: none;
    }
}
.no-hero header .top-menu .pages .menu-item .page_link.members-btn, 
.show-responsive header .top-menu .pages .menu-item .page_link.members-btn, 
header.sticky .top-menu .pages .menu-item .page_link.members-btn{
    color: #fff!important;
}

