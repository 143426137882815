.shake_it_baby {
  outline-color: red;
  /* also need animation and -moz-animation */
  -webkit-animation: shake .5s linear;
  -moz-animation: shake .5s linear;
}

/* also need keyframes and -moz-keyframes */
@keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}
.bounce {
	-webkit-animation: bounce 2s infinite;
	-o-animation: bounce 2s infinite;
	animation: bounce 2s infinite;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}
@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@-webkit-keyframes intro_slideshow {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  33% {
    opacity: 1;
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }
  66% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes intro_slideshow {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}