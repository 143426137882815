.input-group{
	display: block;
}

.form-group{
	margin-right: 0!important;
    margin-left: 0!important;
	.form-control-feedback {
			top: 38px;
	}
	label {
	    font-weight: 500;
	}
	.option{
			float: left;
	}
	.form-control{
			border-radius: 0;
			height: 45px;
			@media screen and (max-width: 991px) {
					height: 40px;
			}
			@media screen and (max-width: 767px) {
					height: 35px;
			}

	}
	textarea{
			&.form-control{
					height: 150px;
			}
	}

}
.g-recaptcha{
		@media screen and (max-width: 767px) {
			float: right;
			margin-bottom: 15px;
		}
}


.contact{
		padding: 80px 0 100px 0;
		@media screen and (max-width: 767px) {
			padding: 30px 0 50px 0;
		}
		.contact-info{
			@media only screen and (max-width: 767px){
				width: 50%;
				float: left;
				ul{
					margin: 0 auto 20px;
    				display: table;
				}
				
			}
			@media only screen and (max-width: 480px){
				width: 100%;
				float: left;
			}
			ul{
				padding: 0;
				list-style: none;
				margin-bottom: 20px;

				
				li{
						margin-bottom: 2px;
						&:nth-child(1){
							font-size: 20px;
							font-weight: 500;
						}
						a{
							color: #fff;
							background-color: #001a33;
							border-radius: 5em;
							padding: 1px 12px;
							text-decoration: none;
							font-size: 16px;
							font-weight: 700;
						}
				}
			}

			
		}
		.contact-form{
				
				.btn-primary{
						font-size: 18px;
						font-family: "Roboto Condensed",sans-serif;
						font-weight: 400;
						padding: 6px 20px;
						border-radius: 0;
						background: $brand_color;
						border:2px solid $brand_color;
						&:focus,
						&:hover{
								background: #FFF;
								color:$brand_color;
						}
				}
		}
}
.bases-map{
	padding: 95px 0;
	@media only screen and (max-width: 991px){
		padding: 75px 0;
	}
	@media only screen and (max-width: 767px){
		padding: 55px 0;
	}
}
.our-bases-list{
	.bases-list-item{
		padding-top:30px;
		h2{
			margin-top: 0px!important;
			margin-bottom: 50px!important;
			@media only screen and (max-width: 767px){
				margin-top: 30px!important;
				margin-bottom: 30px!important;
			}
		}

		h5.location-label{
		color: #666;
		font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
		margin-top: 0px!important;
			@media only screen and (max-width: 767px){
				margin-top: 15px!important;
				font-size: 18px;
			}
		}
		ul,p{
			color: #666;
		}

		.map-contact .map-container-contact{
		    height: 100%;
			 padding:50%;
			@media only screen and (max-width: 767px){
				height: 100%;
				min-height: 300px;
				padding:0;
			}
		}
		
	}
	
}
.map-contact{
		.map-container-contact {
				height:60vh;
				min-height: 500px;
				@media only screen and (max-width: 767px){
					min-height: 300px;
				}
				.siteNotice{
						font-weight: 600;
						margin-bottom: 10px;
						font-size: 16px;
				}
				.bodyContent{
						p{
								margin-bottom: 0;
						}
				}

				a{
						color: #000;
						font-size: 12px;
						font-weight: 600;
						margin-top: 10px;
						display: block;
				}
		}
}
.lefkas-image,
.our-bases-map{
	border: 1px solid #ddd;
	
	@media only screen and (max-width: 767px){
		margin: 0 -15px;
	}
}
.lefkas-image{
	height:350px;
	margin-top: 30px;
	background-position: center left;
	background-repeat: no-repeat;
	background-size: cover;
}
.our-bases-content{
	padding-bottom: 30px;
	@media only screen and (max-width: 767px){
		min-height: auto!important;
	}
	ul{
		column-count: 2;
	    -moz-column-gap: 40px;
	    -webkit-column-gap: 40px;
	    column-gap: 40px;
	    -moz-column-width: 400px;
	    -webkit-column-width: 400px;
	    column-width: 100px;
	}
	
}
#contact {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: $brand_color;
	padding: 100px 0 150px 0;

	p {
   		&.heading {
			font-size: 56px;
			font-family: $Font_Regular;
			line-height: 1.05;
			@media only screen and (max-width: 1200px){
				font-size: 50px;
			}
			@media only screen and (max-width: 991px){
				font-size: 40px;
			}
			@media only screen and (max-width: 767px){
				font-size: 30px;
			}
			&.animate {
				-webkit-transition: all 0.4s ease-out;
				-moz-transition: all 0.4s ease-out;
				-ms-transition: all 0.4s ease-out;
				-o-transition: all 0.4s ease-out;
				transition: all 0.4s ease-out;
				-webkit-transform: translate3d(0, 30px, 0);
				-moz-transform: translate3d(0, 30px, 0);
				-ms-transform: translate3d(0, 30px, 0);
				transform: translate3d(0, 30px, 0);
				opacity: 0;
				&.visible {
					-webkit-transform: translate3d(0, 0, 0);
					-moz-transform: translate3d(0, 0, 0);
					-ms-transform: translate3d(0, 0, 0);
					transform: translate3d(0, 0, 0);
					opacity: 1;
				}
			}
		}
	}
	.descr {
		padding: 20px 40px;
    	background-color: rgba(255,255,255, .8);
		font-weight: 700;

		@media only screen and (max-width: 767px){
			margin: 0 auto;
			float: none!important;
			display:table;
		}
   }
}



// Thanks to: https://www.antimath.info/css/sass-sqrt-function/
// Using sqrt to get the size of the map pin. You could do this manually,
// if your pin is a different shape.
@function sqrt($r) {
    $x0: 1;
    $x1: $x0;
    @for $i from 1 through 10 {
        $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
        $x0: $x1;
    }
    @return $x1;
}
$pinWidth: 48px;
$pinHeightFactor: ((1 + sqrt(2))/2);
$pinHeight: $pinHeightFactor * $pinWidth;
$pinColor: #001a33;
$shadowOpacity: .2;
$shadow-size: 50px;
$pulseSize: 100px;
.pin-wrap {
    position: absolute;
    width: $pinWidth;
    height: $pinWidth;
    margin-top: -$pinHeight;
    margin-left: -$pinWidth/2;
 
	background-image: url(../img/marker.png);
	background-repeat: no-repeat;
}
.pin {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $pinWidth;
    height: $pinWidth;
    margin-top: -$pinWidth/2;
    margin-left: -$pinWidth/2;
    transform-origin: 50% ($pinHeightFactor * 100%) 0;
}

.shadow {
    position: absolute;
}
.shadow::after {
    position: absolute;
    left:-120px;
    display: block;
    width: 35px;
    height: 35px;
    margin-top: -$shadow-size/2;
    content: '';
    transform: rotateX(55deg);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, $shadowOpacity) 100px 0 20px;
}
.pulse {
    position: absolute;
    margin-top: -$pulseSize/2;
    margin-left: -$pulseSize/2;
    transform: rotateX(55deg);
}
.pulse::after {
    display: block;
    width: $pulseSize;
    height: $pulseSize;
    content: '';
    animation: pulsate 1s ease-out;
    animation-delay: 1.1s;
    animation-iteration-count: infinite;
    opacity: 0;
    border-radius: 50%;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, $shadowOpacity);
    box-shadow: 0 0 6px 3px rgba($pinColor, 1.0);
}
.pin-name{
	text-decoration: none;
	text-transform: capitalize;
	border: none;
    position: absolute;
    margin-top: -$pinWidth/2;
    margin-left: $pinWidth/4;
    font-size: 18px;
    font-weight: 400;
    @media only screen and (max-width: 767px){
    	 bottom: -74px;
		left: -45px;

    }
    
}
.pin-line{
	width: 2px;
    height: 80px;
    background-color:#231f20;
    position:relative;
     @media only screen and (max-width: 767px){
     	 height: 50px;
     }
    &:after{
    	top: 0;
    	content: "";
    	left: -4px;
    	position:absolute;
    	width: 10px;
    	height: 10px;
    	border-radius: 30px;
    	background-color: #0b67b1;
    }
}

.pin-anchor-link{
	margin-top: 0!important;
	.pin-wrap {
		margin-top: 0;
	}
	.pin {
		margin-top: 0;
	}

	 &.alimos,
	 &.mykonos{
	 	.pin-line{
	 		width: 80px;
    		height: 2px;
    		margin-left: 5px;
    		 @media only screen and (max-width: 767px){
    		 	width: 35px;
    		 }
		     &:after{
		     	top:-4px;
		     }
	 	}
	 	.pin-name{
	 	    margin-left: 25px;
	 	     @media only screen and (max-width: 767px){
	 	     	top: -25px;
	 	     	margin-left: 10px;
   				left: 0;
	 	     }
	 	    
	 	}
	 	.pin-wrap{
	 		left: 90px;
		    top: -35px;
		    margin-left: 0;
		     @media only screen and (max-width: 767px){
		     	left: 35px;
		     }
	 	}
	 	
	 }

	  &.lefkada{
	 	.pin-line{
	 		width: 80px;
    		height: 2px;
    		margin-left: 5px;
    		left: -85px;
    		&:after{
    			left: 74px;
    			top:-4px;
    			@media only screen and (max-width: 767px){
    				left: 0px;
    			}
    		}
    		 @media only screen and (max-width: 767px){
    		 	width: 35px;
    		 	left: 0px;
    		 }
		  
	 	}
	 	.pin-name{
	 	    margin-left: -72px;
	 	     @media only screen and (max-width: 767px){
	 	     	margin-left: 10px;
	 	     	top: -25px;
   				left: 0;
	 	     }
	 	    
	 	}
	 	.pin-wrap{
	 		left: -130px;
		    top: -35px;
		    margin-left: 0;
		     @media only screen and (max-width: 767px){
		     	left: 35px;
		     }
	 	}
	 	
	 }

}
@keyframes pulsate {
    0% {
        transform: scale(.1, .1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}
.contact-list-inline{
	display: table;
	margin:0  auto;
	padding:15px 0;
	li{
		padding:0px 25px ;
		 @media only screen and (max-width: 767px){
	    	padding:0px 5px ;
	    }
		&:nth-child(1){border-right: 2px solid #000;}
		a{
			text-decoration: none;
			font-weight: 700;
			 @media only screen and (max-width: 767px){
		    	font-size: 16px;
		    }
			.fa{
				color:$call_to_action;;
				margin-right: 6px;
				font-size: 21px;
				 @media only screen and (max-width: 767px){
			    	font-size: 16px;
			    }
			}
		}
	}
}
.has-feedback label~.form-control-feedback {
    top: 38px;
}

.option.has-error{
	 &.has-feedback [type=checkbox]:checked+label:before, &.has-feedback [type=checkbox]:not(:checked)+label:before{
	 	border: 1px solid #a94442!important;
	 }
	  ~.form-control-feedback{
	  	display: none!important;
	  }
}

.has-error{
	.select2-container--default .select2-selection--single {
	    border: 1px solid #a94442;
	   
	}
	.select2~.form-control-feedback{
	  	display: none!important;
	  }
}