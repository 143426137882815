$checkbox-border : #333;
$checkbox-border-filled : #333;
$checkbox-bg : transparent;
$checkbox-bg-filled : transparent;

.option{
		[type="checkbox"]{
			&:not(:checked),
			&:checked {
				position: absolute;
				left: -9999px;
			}
			+ label {
				margin: 0;
				position: relative;
				cursor: pointer;
				padding: 12px 15px 12px 55px;
				font-size: 14px;
				letter-spacing: -0.3px;

				@media only screen and (max-width: 991px){
						padding: 9px 13px 10px 50px;
						font-size: 14px;
				}
				@media only screen and (max-width: 767px){
					padding: 9px 15px 8px 42px;
					font-size: 12px;
				}
			}
			&:not(:checked) + label:before,
			&:checked + label:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 45px;
	 			height: 45px;
				border: 1px solid $checkbox-border;
				border-radius: 3px;
				background: $checkbox-bg;
				border-radius: 0;
				box-shadow: none;
				@media screen and (max-width: 991px) {
						height: 40px;
						width: 40px;
				}
				@media screen and (max-width: 767px) {
						height: 35px;
						width: 35px;
				}
			}
			&:not(:checked) + label:after,
			&:checked + label:after {
					content: '✔';
					position: absolute;
					left: 18px;
    			top: 13px;
					color: #333;
				  transition: all .2s;
					@media only screen and (max-width: 991px){
							left: 14px;
							top: 10px;
					}
					@media only screen and (max-width: 767px){
						left: 12px;
						top: 8px;
					}
			}
			/* checked mark aspect changes */
			&:not(:checked) + label:after {
			  opacity: 0;
			  transform: scale(0);
			}
			&:checked + label:after {
			  opacity: 1;
			  transform: scale(1);
			}
			/* disabled checkbox */
			&:disabled:not(:checked) + label:before,
			&:disabled:checked + label:before {
			  box-shadow: none;
			  border-color: $checkbox-border;
			  background-color: $checkbox-bg;
			}
			&:disabled:checked + label:after {
			  color: $checkbox-border;
			}

			&:checked + label:before {
				border: 1px solid $checkbox-border-filled;
			}
			&:checked + label {
				color: $checkbox-border-filled;
			}
			/* accessibility */
			.option [type="checkbox"]:checked:focus + label:before,
			.option [type="checkbox"]:not(:checked):focus + label:before {
			 	 border: 2px solid $checkbox-border-filled;
			}
		}
		[type="radio"]{
			&:not(:checked),
			&:checked {
				position: absolute;
				left: -9999px;
			}
			+ label {
				position: relative;
				margin: 0;
				cursor: pointer;
				padding: 12px 15px 12px 55px;
				font-size: 14px;
				letter-spacing: -0.3px;

				@media only screen and (max-width: 991px){
						padding: 9px 13px 10px 50px;
						font-size: 14px;
				}
				@media only screen and (max-width: 767px){
					padding: 9px 15px 8px 42px;
					font-size: 12px;
				}
			}
			&:not(:checked) + label:before,
			&:checked + label:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 45px;
	 			height: 45px;
				border: 1px solid $checkbox-border;
				border-radius: 3px;
				background: $checkbox-bg;
				border-radius: 0;
				box-shadow: none;

				@media screen and (max-width: 991px) {
						height: 40px;
						width: 40px;
				}
				@media screen and (max-width: 767px) {
						height: 35px;
						width: 35px;
				}
			}
			&:not(:checked) + label:after,
			&:checked + label:after {
					content: '✔';
					position: absolute;
					left: 18px;
    			top: 13px;
					color: #333;
				  transition: all .2s;
					@media only screen and (max-width: 991px){
							left: 14px;
							top: 10px;
					}
					@media only screen and (max-width: 767px){
						left: 12px;
						top: 8px;
					}
			}
			/* checked mark aspect changes */
			&:not(:checked) + label:after {
			  opacity: 0;
			  transform: scale(0);
			}
			&:checked + label:after {
			  opacity: 1;
			  transform: scale(1);
			}
			/* disabled checkbox */
			&:disabled:not(:checked) + label:before,
			&:disabled:checked + label:before {
			  box-shadow: none;
			  border-color: $checkbox-border;
			  background-color: $checkbox-bg;
			}
			&:disabled:checked + label:after {
			  color: $checkbox-border;
			}

			&:checked + label:before {
				border: 1px solid $checkbox-border-filled;
			}
			&:checked + label {
				color: $checkbox-border-filled;
			}
			/* accessibility */
			.option [type="checkbox"]:checked:focus + label:before,
			.option [type="checkbox"]:not(:checked):focus + label:before {
			 	 border: 2px solid $checkbox-border-filled;
			}

		}
		label:hover:before {
			border-color: $checkbox-border-filled!important;
		}
}
